import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../helpers/apiCall";

export const obtenerFoto = createAsyncThunk(
    'consultaAlumno/foto',
    async({userId, token}, {rejectWithValue}) => {
        try {

            const { data } = await apiCall('alumnos/images/photo?itemId=imagenFoto&itemValue='+ userId,{}, {'Authorization': 'Bearer ' + token}, 'GET', 'arraybuffer');
            return btoa(
                new Uint8Array(data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            )
        } catch(error) {
            if (error.response && error.response.data.error) {
                return rejectWithValue(error.response.data.error)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

export const reporteAlumno = createAsyncThunk(
    'cuestionario/reporte',
    async ({ idAlumno,idCuestionario, token }, { rejectWithValue }) => {
        try {

            const { data } = await apiCall(`alumnos/encuestas/reportes/${idAlumno}/${idCuestionario}//constancia_encuesta.pdf`, {}, { 'Authorization': 'Bearer ' + token }, 'GET', 'arraybuffer');
            return btoa(
                new Uint8Array(data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            )
        } catch (error) {
            if (error.response && error.response.data.error) {
                return rejectWithValue(error.response.data.error)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);