import { Paper, Grid, Container, Typography, Alert, Button } from "@mui/material";
import { List, ListItem, ListItemText } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/Spinner";
import Avatar from '@mui/material/Avatar';
import React, { useState } from "react";
import { useObtenerAlumnoQuery } from "../services/perfil.service";
import { useVerificarEncuestaQuery } from "../services/encuesta.service";
import { obtenerFoto} from "../actions/perfilActions";
import { reporteAlumno } from "../actions/consultaAlumnoAction";
import { useNavigate } from "react-router-dom";
import DescriptionIcon from '@mui/icons-material/Description';


const Dashboard = () => {
    const navigate = useNavigate();
    const { userInfo } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [image, setImage] = useState("");

    const { realizoEncuesta, statusValidar, error } = useSelector((state) => state.encuestas);

    const { data: alumno, isLoading } = useObtenerAlumnoQuery(userInfo.userId);

    React.useEffect(() => {
        if (!isLoading && statusValidar === 'succeeded' && !image) {
            dispatch(obtenerFoto({ userId: userInfo.userId, token: userInfo.token })).then(response => {
                setImage(response.payload)
            })
        }
    });
    const VerificarEncuestaLoader = (props) => {
        useVerificarEncuestaQuery({ idCuestionario: 6, idAlumno: userInfo.userId })
        return;
    };

    const [reporte, setReporte] = useState("");

    const handleReporteAlumnoClick = () => {
        dispatch(reporteAlumno({ idAlumno: userInfo.userId, token: userInfo.token, idCuestionario: 6 }))
            .then(({ payload }) => {
                setReporte(payload);
                // Decode the base64 string into a binary string
                var binaryString = window.atob(payload);
                // Create a Uint8Array from the binary string
                var uint8Array = new Uint8Array(binaryString.length);
                for (var i = 0; i < binaryString.length; i++) {
                    uint8Array[i] = binaryString.charCodeAt(i);
                }
                // Create a Blob object from the Uint8Array
                var blob = new Blob([uint8Array], { type: 'application/pdf' }); // Change the MIME type to 'application/pdf'
                // Create a URL for the Blob object
                var url = window.URL.createObjectURL(blob);
                // Create a link element and set its href attribute to the URL
                var link = document.createElement('a');
                link.href = url;
                // Set the download attribute to specify the filename
                link.setAttribute('download', 'reporte_Alumno.pdf'); // Change the filename extension to '.pdf'
                // Append the link element to the document body
                document.body.appendChild(link);
                // Click the link to trigger the download
                link.click();
                // Remove the link element from the document body
                document.body.removeChild(link);
            })
            .catch(error => {
                console.log('Error al obtener el reporte:', error);
            });
    };

    return (<Container maxWidth={false} sx={{ mt: 4, mb: 4 }} style={{ minHeight: "100vh", backgroundColor: '#f5f5f5' }}>
        <Grid container spacing={3} style={{ marginTop: '64px', marginLeft: '10px' }}>
            <Paper
                sx={{
                    p: 2, display: 'flex', flexDirection: 'column',
                }}
            >
                <Typography variant="h4">
                    Bienvenido {userInfo.userName}
                </Typography>
                <Avatar src={`data:image/jpeg;charset=utf-8;base64,${image}`} sx={{ width: 120, height: 120 }} />
                {!isLoading && (<>
                    <VerificarEncuestaLoader />
                    {statusValidar === 'succeeded' && image && (<>
                        {
                            !realizoEncuesta && <Grid item ml={2}>
                                
                                    <Button variant="contained" color="primary" onClick={() => {
                                        navigate('/encuesta', { state: { cuestionario: 6 } })
                                    }}>
                                        Contestar Encuesta
                                    </Button>

                                    <Button variant="contained" color="success" onClick={handleReporteAlumnoClick} style={{marginLeft: '10px'}}>
                                        <DescriptionIcon></DescriptionIcon>Reporte</Button>
                                
                            </Grid>
                        }
                        {
                            realizoEncuesta && <Grid item ml={2}>
                                
                                    <Button variant="contained" color="primary" onClick={() => {
                                        navigate('/encuesta', { state: { cuestionario: 6 } })
                                    }}>
                                        Actualizar Datos en la Encuesta
                                    </Button>

                                    <Button variant="contained" color="success"  onClick={handleReporteAlumnoClick} style={{marginLeft: '10px'}}>
                                        <DescriptionIcon></DescriptionIcon>Reporte</Button>
                                
                            </Grid>
                        }
                    </>)
                    }
                </>)
                }
                {alumno ? <List>
                    <ListItem sx={{ pt: 0, mt: 0 }}>
                        <ListItemText sx={{ mt: 0 }} primary={"Número de control: "} secondary={alumno.numeroControl} />
                    </ListItem>
                    <ListItem sx={{ pt: 0, mt: 0 }}>
                        <ListItemText sx={{ mt: 0 }} primary={"Nombre: "}
                            secondary={`${alumno.nombre} ${alumno.apellidoPaterno} ${alumno.apellidoMaterno}`} />
                    </ListItem>
                    <ListItem sx={{ pt: 0, mt: 0 }}>
                        <ListItemText sx={{ mt: 0 }} primary={"Fecha de nacimiento: "}
                            secondary={alumno.fechaNacimiento} />
                    </ListItem>
                    <ListItem sx={{ pt: 0, mt: 0 }}>
                        <ListItemText sx={{ mt: 0 }} primary={"CURP: "} secondary={alumno.curp} />
                    </ListItem>
                    <ListItem sx={{ pt: 0, mt: 0 }}>
                        <ListItemText sx={{ mt: 0 }} primary={"Género: "} secondary={alumno.sexoDescripcion} />
                    </ListItem>
                    <ListItem sx={{ pt: 0, mt: 0 }}>
                        <ListItemText sx={{ mt: 0 }} primary={"Correo electrónico: "}
                            secondary={alumno.correoElectronico} />
                    </ListItem>
                    <ListItem sx={{ pt: 0, mt: 0 }}>
                        <ListItemText sx={{ mt: 0 }} primary={"Teléfono: "} secondary={alumno.telefono} />
                    </ListItem>
                    <ListItem sx={{ pt: 0, mt: 0 }}>
                        <ListItemText sx={{ mt: 0 }} primary={"Dirección: "}
                            secondary={`${alumno.colonia} ${alumno.calle} No.${alumno.numeroExterior} CP.${alumno.codigoPostal}`} />
                    </ListItem>
                    <ListItem sx={{ pt: 0, mt: 0 }}>
                        <ListItemText sx={{ mt: 0 }} primary={"Estatus: "} secondary={alumno.estatus.estatus} />
                    </ListItem>
                </List> : ''
                }
                {statusValidar === "loading" || isLoading ?
                    <Grid container justifyContent="center" alignItems="center" direction="column" mt={9}>
                        <Spinner />
                    </Grid> : ''}
                {statusValidar === "failed" ?
                    <Grid container justifyContent="center" alignItems="center" direction="column" mt={9}>
                        <Alert severity="error">
                            <Typography>
                                {error}
                            </Typography>
                        </Alert>
                    </Grid>
                    : ""}
            </Paper>
        </Grid>
    </Container>);
};

export default Dashboard;
