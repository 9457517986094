import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const API_BASE_URL = 'http://51.222.44.69:8022/Dev/Control/';

export const consultaApi = createApi({
    reducerPath: 'consultaApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        obtenerCarrera: build.query({
            query: () => `ofertas/educativas/json/list/1/1`,

        }),
        obtenerEgresado: build.query({
            query: () => `seguimiento/egresados/json/list/generaciones`,
            forceRefetch({ state}) {
                return state.consulta.statusEgresado === 'idle';
            }
        }),
        obtenerEgresadoGeneracion: build.query({
            query: () => `seguimiento/egresados/json/list/carreras`,
            forceRefetch({ state}) {
                return state.consulta.statusEgresado === 'idle';
            }
        }),
        obtenerAnio: build.query({
            query: () => `seguimiento/egresados/json/list/anios`,

        }),
        obtenerDetalles1: build.query({
            query: (idCarrera) => `seguimiento/egresados/json/list/generaciones/detalles/${idCarrera}`,
            // Solo tenga una entrada de caché porque el argumento siempre se asigna a una cadena
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            forceRefetch({ state, currentArg, previousArg }) {
                return currentArg !== previousArg  || state.consulta.statusDetalles === 'idle';
            },
        }),
        obtenerDetalles2: build.query({
            query: ({ idCarrera, anio }) => `seguimiento/egresados/json/list/generaciones/detalles/${idCarrera}/${anio}`,
            // Solo tenga una entrada de caché porque el argumento siempre se asigna a una cadena
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            forceRefetch({ state, currentArg, previousArg }) {
                return currentArg?.idCarrera !== previousArg?.idCarrera || currentArg?.anio !== previousArg?.anio || state.consulta.statusDetalles === 'idle';
            },
        }),
        reporteDetalles: build.query({
            query: ({ anio }) => `cuestionarios/reportes/Ng/resultado_cuestionario_${anio}.xlsx`,
            // Solo tenga una entrada de caché porque el argumento siempre se asigna a una cadena
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg?.idCuesionario !== previousArg?.idCuesionario || currentArg?.anio !== previousArg?.anio
            },
        }),
        obtenerDetallesEncuesta: build.query({
            query: ({ idCarrera, anio, fechaInicio, fechaFinal }) =>
              `seguimiento/egresados/json/list/generaciones/encuestados/${idCarrera}/${anio}?fechaInicio=${fechaInicio}&fechaFinal=${fechaFinal}`,
            // Solo tenga una entrada de caché porque el argumento siempre se asigna a una cadena
            serializeQueryArgs: ({ endpointName }) => {
              return endpointName;
            },
            forceRefetch({ state, currentArg, previousArg }) {
                return currentArg?.idCarrera !== previousArg?.idCarrera || currentArg?.fechaInicio !== previousArg?.fechaInicio || currentArg?.fechaFinal !== previousArg?.fechaFinal || state.consulta.statusDetalles === 'idle';
            },
          }),
          obtenerDetallesEncuesta2: build.query({
            query: ({ idCarrera, fechaInicio, fechaFinal }) =>
              `seguimiento/egresados/json/list/generaciones/encuestados/${idCarrera}?fechaInicio=${fechaInicio}&fechaFinal=${fechaFinal}`,
            // Solo tenga una entrada de caché porque el argumento siempre se asigna a una cadena
            serializeQueryArgs: ({ endpointName }) => {
              return endpointName;
            },
            forceRefetch({ state, currentArg, previousArg }) {
                return currentArg?.idCarrera !== previousArg?.idCarrera || currentArg?.fechaInicio !== previousArg?.fechaInicio || currentArg?.fechaFinal !== previousArg?.fechaFinal || state.consulta.statusDetalles === 'idle';
            },
          }),
    }),
});
export const { useObtenerCarreraQuery, useObtenerEgresadoQuery, useObtenerDetalles1Query, useObtenerDetalles2Query, useObtenerAnioQuery, useReporteDetallesQuery, useObtenerEgresadoGeneracionQuery, useObtenerDetallesEncuestaQuery, useObtenerDetallesEncuesta2Query } = consultaApi;