import React, { Fragment, useCallback } from "react"; //, useState
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Login from "./views/Login";
import { Box, CssBaseline } from "@mui/material";
import Copyright from "./components/Copyright";
import Dashboard from "./views/Dashboard";
import Encuesta from "./views/Encuesta";
import ProtectedRoute from "./routing/ProtectedRoute";
import Barras from "./components/Barras";
import EmpresasRoute from "./routing/EmpresasRoute";
import DashboardEmpresas from "./views/DashBoardEmpresas";
import AccesoEmpresas from "./views/AccesoEmpresas";
import AdminDashboard from "./views/AdminDashboard";
import AdminConsultas from "./views/AdminConsultas";
import AdminConsultasEmpresas from "./views/AdminConsultasEmpresas";
import AdminConsultasAlumnos from "./views/AdminConsultasAlumnos";
import { logout } from "./slices/authSlice";
import { useDispatch } from "react-redux";
import AuthVerify from "./helpers/AuthVerify";
import { esES } from '@mui/material/locale';
import { esES as gridES } from '@mui/x-data-grid';

const theme = createTheme({}, esES, gridES);

function App() {
  const dispatch = useDispatch();
  const logOut = useCallback(() => {
    dispatch(logout())
  }, [dispatch]);
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Router>
          <Routes>
            <Fragment>
              <Route path="/" element={<Login />} />
              <Route path="/accesoEmpresas" element={<AccesoEmpresas />} />
              <Route element={<Barras />}>
                <Route element={<ProtectedRoute />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route
                    path="/encuesta"
                    element={<Encuesta />}
                  />
                  <Route path="/admin/dashboard" element={<AdminDashboard />} />
                  <Route path="/admin/consultas" element={<AdminConsultas />} />
                  <Route path="/admin/consultasEmpresas" element={<AdminConsultasEmpresas />} />
                  <Route path="/admin/consultasAlumnos" element={<AdminConsultasAlumnos />} />
                </Route>
                <Route element={<EmpresasRoute />}>
                  <Route path="/empresas/dashboard" element={<DashboardEmpresas />} />
                  <Route
                    path="/encuesta"
                    element={<Encuesta />}
                  />
                </Route>
              </Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Fragment>
          </Routes>
          <AuthVerify logOut={logOut} />
        </Router>

        <Copyright />


      </Box>
    </ThemeProvider>
  );
}

export default App;
