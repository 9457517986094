import { Paper, Grid, Container, Typography, Alert, Button } from "@mui/material";
import { List, ListItem, ListItemText } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/Spinner";
import Avatar from '@mui/material/Avatar';
import React, { useState } from "react";
import { useObtenerAlumnoQuery } from "../services/perfil.service";
import { useVerificarEncuestaQuery } from "../services/encuesta.service";
import { obtenerFoto } from "../actions/perfilActions";
import { useNavigate } from "react-router-dom";

const PerfilAlumno = ({ idAlumno, children }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [image, setImage] = useState("");

  const { realizoEncuesta, fechaRealizoEcuesta, statusValidar, error } = useSelector((state) => state.encuestas);

  const { data: alumno, isLoading } = useObtenerAlumnoQuery(idAlumno);

  const { userToken } = useSelector((state) => state.auth);

  React.useEffect(() => {
    if (!isLoading && statusValidar === 'succeeded' && !image) {
      dispatch(obtenerFoto({ userId: idAlumno, token: userToken })).then(response => {
        setImage(response.payload)
      })
    }
  });
  const VerificarEncuestaLoader = (props) => {
    useVerificarEncuestaQuery({ idCuestionario: 6, idAlumno: idAlumno })
    return;
  };

  const button = React.Children.map(children, child => {
    if (React.isValidElement(child) && child.type === Button) {
      return child;
    }
    return null;
  });

  return (<Container maxWidth={false} sx={{ mt: 4, mb: 4 }} style={{ minHeight: "100vh", backgroundColor: '#f5f5f5' }}>
    <Grid container spacing={3} style={{ marginTop: '64px', marginLeft: '10px' }}>
      <Paper
        sx={{
          p: 2, display: 'flex', flexDirection: 'column', width: "50vh"
        }}
      >
        <Avatar src={`data:image/jpeg;charset=utf-8;base64,${image}`} sx={{ width: 120, height: 120 }} />
        {!isLoading && (<>
          <VerificarEncuestaLoader />
          {statusValidar === 'succeeded' && image && (<>
            {realizoEncuesta ? (
              <Typography variant="h7" style={{ fontWeight: 'bold', color: "#1976d2", marginBottom: '0', paddingTop: '5px', marginLeft: 15 }}>
                Contestó la encuesta el día {fechaRealizoEcuesta}
              </Typography>
            ) : (
              <Typography variant="h7" style={{ fontWeight: 'bold', color: "#d32f2f", marginBottom: '0', paddingTop: '5px', marginLeft: 15 }}>
                Aún no ha realizado la encuesta
              </Typography>
            )}
          </>
          )}
        </>
        )}
        {alumno ? <List>
          <ListItem sx={{ pt: 0, mt: 0 }}>
            <ListItemText sx={{ mt: 0 }} primary={"Número de control: "} secondary={alumno.numeroControl} />
          </ListItem>
          <ListItem sx={{ pt: 0, mt: 0 }}>
            <ListItemText sx={{ mt: 0 }} primary={"Nombre: "}
              secondary={`${alumno.nombre} ${alumno.apellidoPaterno} ${alumno.apellidoMaterno}`} />
          </ListItem>
          <ListItem sx={{ pt: 0, mt: 0 }}>
            <ListItemText sx={{ mt: 0 }} primary={"Fecha de nacimiento: "}
              secondary={alumno.fechaNacimiento} />
          </ListItem>
          <ListItem sx={{ pt: 0, mt: 0 }}>
            <ListItemText sx={{ mt: 0 }} primary={"CURP: "} secondary={alumno.curp} />
          </ListItem>
          <ListItem sx={{ pt: 0, mt: 0 }}>
            <ListItemText sx={{ mt: 0 }} primary={"Género: "} secondary={alumno.sexoDescripcion} />
          </ListItem>
          <ListItem sx={{ pt: 0, mt: 0 }}>
            <ListItemText sx={{ mt: 0 }} primary={"Correo electrónico: "}
              secondary={alumno.correoElectronico} />
          </ListItem>
          <ListItem sx={{ pt: 0, mt: 0 }}>
            <ListItemText sx={{ mt: 0 }} primary={"Teléfono: "} secondary={alumno.telefono} />
          </ListItem>
          <ListItem sx={{ pt: 0, mt: 0 }}>
            <ListItemText sx={{ mt: 0 }} primary={"Dirección: "}
              secondary={`${alumno.colonia} ${alumno.calle} No.${alumno.numeroExterior} CP.${alumno.codigoPostal}`} />
          </ListItem>
          <ListItem sx={{ pt: 0, mt: 0 }}>
            <ListItemText sx={{ mt: 0 }} primary={"Estatus: "} secondary={alumno.estatus.estatus} />
          </ListItem>
        </List> : ''
        }
        {button}
        {statusValidar === "loading" || isLoading ?
          <Grid container justifyContent="center" alignItems="center" direction="column" mt={9}>
            <Spinner />
          </Grid> : ''}
        {statusValidar === "failed" ?
          <Grid container justifyContent="center" alignItems="center" direction="column" mt={9}>
            <Alert severity="error">
              <Typography>
                {error}
              </Typography>
            </Alert>
          </Grid>
          : ""}
      </Paper>
    </Grid>
  </Container>);
};

export default PerfilAlumno;
