import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { Undo as UndoIcon } from '@mui/icons-material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { reporteEmpresa } from "../actions/consultaEmpresaAction";
import { concentradoDetalle } from "../actions/consultaEmpresaAction";
import CustomDataGrid from "../components/encuestas/CustomDataGrid";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
    Grid, Button, Typography
} from "@mui/material";

import {
    useObtenerEmpresaQuery,
} from "../services/consultaEmpresa.service";
import { GridToolbarExport, GridToolbarContainer, GridToolbarDensitySelector, GridPagination } from '@mui/x-data-grid';

dayjs.locale('es');

const AdminConsultasEmpresas = () => {
    const {
        statusCarrera, statusDetalles, carreras, egresados, detalles
    } = useSelector((state) => state.consulta);

    const [inputFecha1, setInputFecha1] = useState('');
    const [inputFecha2, setInputFecha2] = useState('');
    const [fecha1, setFecha1] = useState(null);
    const [fecha2, setFecha2] = useState(null);

    const [empresas, setEmpresas] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);

    const [showTable, setShowTable] = useState(false);

    // const { data: empresas, isSuccess } = useObtenerEmpresaQuery({ fechaInicio: inputFecha1, fechaFinal: inputFecha2 });



    const columns = [
        {
            field: 'rfc',
            headerName: 'RFC',
            width: 150
        },
        {
            field: 'empresa',
            headerName: 'Empresa',
            flex: 1,
        },
        {
            field: 'fecha',
            headerName: 'F. Encuesta',
            width: 150
        },


    ];

    const GetEmpresas = (props) => {
        const { data: empresasData, isSuccess: isSuccessData } = useObtenerEmpresaQuery(props);
        useEffect(() => {
            if (empresasData?.length > 0 && isSuccessData) {
                setEmpresas(empresasData);
                setIsSuccess(isSuccessData);
            }
        }, [empresasData, isSuccessData]);
        return;
    }

    // const cambioConsulta = () => {
    //     // setFecha1(formatDate(inputFecha1));
    //     // setFecha2(formatDate(inputFecha2));
    // };

    const borrarConsulta = () => {
        setShowTable(false);
        setEmpresas([]);
        setIsSuccess(false);
        setFecha1('');
        setInputFecha1('');
        setFecha2('');
        setInputFecha2('');
    };

    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return `${day}/${month}/${year}`;
    };

    const { userInfo } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [reporte, setReporte] = useState("");

    const FechaLoader = (props) => {
        useObtenerEmpresaQuery(props)
        return;
    };

    const handleReporteEmpresaClick = () => {
        dispatch(reporteEmpresa({ token: userInfo.token }))
            .then(({ payload }) => {
                setReporte(payload);
                // Decode the base64 string into a binary string
                var binaryString = window.atob(payload);
                // Create a Uint8Array from the binary string
                var uint8Array = new Uint8Array(binaryString.length);
                for (var i = 0; i < binaryString.length; i++) {
                    uint8Array[i] = binaryString.charCodeAt(i);
                }
                // Create a Blob object from the Uint8Array
                var blob = new Blob([uint8Array], { type: 'application/vnd.ms-excel' });
                // Create a URL for the Blob object
                var url = window.URL.createObjectURL(blob);
                // Create a link element and set its href attribute to the URL
                var link = document.createElement('a');
                link.href = url;
                // Set the download attribute to specify the filename
                link.setAttribute('download', `resultado_cuestionario.xlsx`);
                // Append the link element to the document body
                document.body.appendChild(link);
                // Click the link to trigger the download
                link.click();
                // Remove the link element from the document body
                document.body.removeChild(link);

            })
            .catch(error => {
                console.log("Error al obtener el reporte:", error);
            });
    };

    const handleConcentradoClick = () => {
        dispatch(concentradoDetalle({ token: userInfo.token }))
            .then(({ payload }) => {
                setReporte(payload);
                // Decode the base64 string into a binary string
                var binaryString = window.atob(payload);
                // Create a Uint8Array from the binary string
                var uint8Array = new Uint8Array(binaryString.length);
                for (var i = 0; i < binaryString.length; i++) {
                    uint8Array[i] = binaryString.charCodeAt(i);
                }
                // Create a Blob object from the Uint8Array
                var blob = new Blob([uint8Array], { type: 'application/vnd.ms-excel' });
                // Create a URL for the Blob object
                var url = window.URL.createObjectURL(blob);
                // Create a link element and set its href attribute to the URL
                var link = document.createElement('a');
                link.href = url;
                // Set the download attribute to specify the filename
                link.setAttribute('download', `resultado_concentrado.xlsx`);
                // Append the link element to the document body
                document.body.appendChild(link);
                // Click the link to trigger the download
                link.click();
                // Remove the link element from the document body
                document.body.removeChild(link);

            })
            .catch(error => {
                console.log("Error al obtener el reporte:", error);
            });
    };

    return (

        <Grid
            container
            spacing={0}
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            mt={8}
            mb={2}
            sx={{ textAlign: 'center', width: '100%' }}
        >

            <Grid container spacing={2} alignItems="center" mb={2}>
                <Grid item ml={2}>
                    <Box sx={{ width: '300px', flexDirection: 'column', marginTop: '10px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} locale="es">
                            <DatePicker
                                label="Fecha inicio"
                                value={fecha1}
                                onChange={(newFecha1) => setInputFecha1(newFecha1.format('DD/MM/YYYY'))}
                                format='DD/MM/YYYY'
                                disableFuture
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                <Grid item ml={2}>
                    <Box sx={{ width: '300px', flexDirection: 'column', marginTop: '10px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} locale="es">
                            <DatePicker
                                label="Fecha final"
                                value={fecha2}
                                onChange={(newFecha2) => setInputFecha2(newFecha2.format('DD/MM/YYYY'))}
                                format='DD/MM/YYYY'
                                disableFuture
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                <Grid item ml={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setShowTable(true);
                            // cambioConsulta();
                        }}
                    >
                        Consultar
                    </Button>

                </Grid>
                <Grid item ml={2}>
                    <Button variant="contained" color="inherit" onClick={borrarConsulta}>
                        Limpiar
                    </Button>
                </Grid>
            </Grid>

            {showTable && <GetEmpresas fechaInicio={inputFecha1} fechaFinal={inputFecha2} />}


            <Grid container pl={2} pr={2} style={{ cursor: 'pointer' }}>
                <Box sx={{ height: 'calc(100vh - 180px)', width: '100%' }}>
                    {(
                        <CustomDataGrid
                            rows={empresas?.map((empresa, index) => ({
                                id: index + 1,
                                empresa: empresa.empresa.empresa,
                                rfc: empresa.empresa.rfc,
                                fecha: formatDate(empresa.fechaEncuesta),
                            }))}
                            columns={columns}
                            slots={{
                                toolbar: () => (
                                    <GridToolbarContainer>
                                        <GridToolbarDensitySelector />
                                        <GridToolbarExport />
                                        <Button variant="text" onClick={handleReporteEmpresaClick}>
                                            <SummarizeIcon />&nbsp;Reporte
                                        </Button>
                                        <Button variant="text" onClick={handleConcentradoClick}>
                                            <SummarizeIcon />&nbsp;Concentrado
                                        </Button>
                                        <Box sx={{ mt: 5, ml: 11 }}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                CONCENTRADO DE EMPRESAS
                                            </Typography>
                                        </Box>
                                    </GridToolbarContainer>
                                ),
                                pagination: (props) => (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <GridPagination {...props} sx={{ marginLeft: 'auto' }} />
                                    </Box>
                                ),
                            }}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            disableRowSelectionOnClick
                        />
                    )}
                </Box>
            </Grid>

        </Grid>
    );
};

export default AdminConsultasEmpresas;
