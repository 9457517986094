import { Container } from "@mui/material";
import { useSelector } from "react-redux";

const AdminDashboard = () => {
    const { userInfo } = useSelector((state) => state.auth);
    return (
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }} style={{ minHeight: 'calc(100vh - 100px)', backgroundColor: '#f5f5f5', marginTop: '64px', marginLeft: '10px', marginBottom: '25px' }}>

            <iframe width="100%" height="100%" id="reporte" title="reporte"
                src="https://lookerstudio.google.com/embed/reporting/cb5cb36d-b6cc-496b-ad2f-a91d827ba6d4/page/yhvND"
                allowFullScreen></iframe>

        </Container>
    );
};

export default AdminDashboard;