import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { empresaLogin } from "../actions/authActions";
import {Error} from "../components/Error";
import Spinner from "../components/Spinner";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Card,
  CardMedia,
  Paper,
  CardContent
} from "@mui/material";
import imagenFormulario from '../img/img-login.jpg';
import logoItsc from '../img/logo_itsc.png';
import logoGnosis from '../img/login.png';



const AccesoEmpresas = () => {
  const { loading, userInfo, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const valor = searchParams.get('hash');

  //redirige usuarios autenticados a la página de inicio
  useEffect(() => {
    if (userInfo && userInfo.userType === "B") {
      navigate("/dashboard");
    } else if (userInfo && userInfo.userType === "X") {
      navigate("/admin/dashboard");
    } else if (userInfo && userInfo.userType === "C") {
      navigate("/empresas/dashboard")
    }
  }, [navigate, userInfo]);

  const submitForm = (data) => {
    dispatch(empresaLogin(data));
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container sx={{
        background: '#f6f7fb',
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      }}
        spacing={2}
      >
        <Grid item xs={8}>
          <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
            <Card sx={{ display: 'flex' }}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <div style={{ position: 'relative' }}>
                    <CardMedia
                      component={'img'}
                      sx={{
                        maxWidth: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                      image={imagenFormulario} />
                    <div style={{ position: 'absolute', top: 0, left: 0, margin: '20px' }}>

                      <Typography variant="h6" sx={{ color: 'white' }}>Sistema de Seguimiento a Egresados</Typography>
                      <img src={logoItsc} height={'70px'} alt="ITSC" style={{ bottom: 0, left: 0 }} />
                      <img src={logoGnosis} height={'70px'} alt="GNOSIS" style={{ bottom: 0, right: 0 }} />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardContent sx={{ flex: '1 1 auto' }}>
                    <Box
                      component="form"
                      onSubmit={handleSubmit(submitForm)}
                    >
                      {error && <Error>{error}</Error>}
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="value"
                        label="Llave"
                        name="value"
                        value={valor}
                        {...register("value")}
                        autoFocus
                        InputProps={{
                          readOnly: true,
                          sx: { color: "#888888" },
                        }}
                      />

                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        {loading ? <Spinner /> : "Entrar"}
                      </Button>
                    </Box>
                  </CardContent>
                </Grid>
              </Grid>


            </Card>
          </Paper>
        </Grid>

      </Grid>
    </Box>
  );
};

export default AccesoEmpresas;