import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const API_BASE_URL = 'http://51.222.44.69:8022/Dev/Control/';
export const consultaAlumnoApi = createApi({
    reducerPath: 'consultaAlumnoApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        obtenerAlumno: build.query({
            query: ({ numeroControl, nombre }) => `alumnos/json/data/filter?numeroControl=${numeroControl}&nombre=${nombre}`
        }),
    }),
});
export const { useObtenerAlumnoQuery } = consultaAlumnoApi;