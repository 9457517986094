import { Container, Grid, Button, Paper, Typography, Alert } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { List, ListItem, ListItemText } from '@mui/material';
import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import { GridToolbarContainer, GridPagination, GridToolbarDensitySelector } from '@mui/x-data-grid';
import CustomDataGrid from "../components/encuestas/CustomDataGrid";
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { useObtenerAlumnoQuery } from "../services/consultaAlumno.service";
import { obtenerFoto, reporteAlumno } from "../actions/consultaAction";
import Spinner from "../components/Spinner";
import PerfilAlumno from "../components/PerfilAlumno";
import { Undo as UndoIcon } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';

const AdminConsultasAlumnos = () => {
    const { userInfo } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [numeroControl, setNumeroControl] = useState('');
    const [nombre, setNombre] = useState('');
    const [inputNumeroControl, setInputNumeroControl] = useState('');
    const [inputNombre, setInputNombre] = useState('');

    const [selectedIdAlumno, setSeletedIdAlumno] = useState(null);
    const [showGrid, setShowGrid] = useState(true);

    const datos = useObtenerAlumnoQuery({ numeroControl: numeroControl, nombre: nombre }, { skip: !(numeroControl || nombre) });

    const alumnos = datos?.currentData?.data;
    const error = datos?.error?.data;
    const status = datos?.status;

    const [reporte, setReporte] = useState("");

    const handleReporteAlumnoClick = () => {
        dispatch(reporteAlumno({ idAlumno: selectedIdAlumno, token: userInfo.token, idCuestionario: 6 }))
            .then(({ payload }) => {
                setReporte(payload);
                // Decode the base64 string into a binary string
                var binaryString = window.atob(payload);
                // Create a Uint8Array from the binary string
                var uint8Array = new Uint8Array(binaryString.length);
                for (var i = 0; i < binaryString.length; i++) {
                    uint8Array[i] = binaryString.charCodeAt(i);
                }
                // Create a Blob object from the Uint8Array
                var blob = new Blob([uint8Array], { type: 'application/pdf' }); // Change the MIME type to 'application/pdf'
                // Create a URL for the Blob object
                var url = window.URL.createObjectURL(blob);
                // Create a link element and set its href attribute to the URL
                var link = document.createElement('a');
                link.href = url;
                // Set the download attribute to specify the filename
                link.setAttribute('download', 'reporte_Alumno.pdf'); // Change the filename extension to '.pdf'
                // Append the link element to the document body
                document.body.appendChild(link);
                // Click the link to trigger the download
                link.click();
                // Remove the link element from the document body
                document.body.removeChild(link);
            })
            .catch(error => {
                console.log('Error al obtener el reporte:', error);
            });
    };

    const columns = [
        {
            field: 'numeroControl',
            headerName: 'Número de control',
            width: 150
        },
        {
            field: 'nombreCompleto',
            headerName: 'Nombre completo',
            flex: 1
        },
        {
            field: 'carrera',
            headerName: 'Carrera',
            flex: 1
        },
    ];
    const cambioConsulta = () => {
        setNumeroControl(inputNumeroControl);
        setNombre(inputNombre);
    };
    const borrarConsulta = () => {
        setNumeroControl('');
        setNombre('');
        setInputNombre('');
        setInputNumeroControl('');
    };

    function handleCellClick(e) {
        setSeletedIdAlumno(e.row.id);
        setShowGrid(false);
    }

    return (<>
        {showGrid && <Grid
            container
            spacing={0}
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            mt={8}
            mb={2}
            sx={{ textAlign: 'center', width: '100%' }}
        >
            <Grid container spacing={2} alignItems="center" mb={2}>
                <Grid container spacing={2} alignItems="center" mb={2}>  </Grid>
                <Grid item ml={2}>
                    <TextField
                        id="numeroControl"
                        label="Número de control"
                        variant="outlined"
                        value={inputNumeroControl}
                        onChange={(e) => { setInputNumeroControl(e.target.value) }}
                        sx={{ width: '300px', flexDirection: 'column', marginTop: '10px' }} />
                </Grid>
                <Grid item ml={2}>
                    <TextField
                        id="nombre"
                        label="Nombre"
                        variant="outlined"
                        value={inputNombre}
                        onChange={(e) => { setInputNombre(e.target.value) }}
                        sx={{ width: '300px', flexDirection: 'column', marginTop: '10px' }} />
                </Grid>

                <Grid item ml={2}>
                    <Button variant="contained" color="primary" onClick={cambioConsulta}>
                        Consultar
                    </Button>
                </Grid>
                <Grid item ml={2}>
                    <Button variant="contained" color="inherit" onClick={borrarConsulta}>
                        Limpiar
                    </Button>
                </Grid>
            </Grid>

            {<Grid container pl={2} pr={2} style={{ cursor: 'pointer' }}>
                {status === "pending" ?
                    <Grid container justifyContent="center" alignItems="center" direction="column" mt={9}>
                        <Spinner />
                    </Grid> : ""}
                {error ?
                    <Alert severity="error">
                        <Typography>
                            {error[Object.keys(error)[0]]}
                        </Typography>
                    </Alert> : ""}
                <Box sx={{ height: 'calc(100vh - 180px)', width: '100%' }}>
                    <CustomDataGrid
                        rows={alumnos?.map((alumno) => ({
                            id: alumno.idAlumno,
                            numeroControl: alumno.numeroControl,
                            nombreCompleto: alumno.nombreCompletoOrden,
                            carrera: alumno.ofertaEducativa.carrera
                        })) || []}
                        columns={columns}
                        slots={{
                            toolbar: () => (
                                <GridToolbarContainer>
                                    <GridToolbarDensitySelector />
                                    <Box sx={{ mt: 5, ml: 45 }}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                            LISTA DE EGRESADOS
                                        </Typography>
                                    </Box>
                                </GridToolbarContainer>
                            ),
                            pagination: (props) => (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <GridPagination {...props} sx={{ marginLeft: 'auto' }} />
                                </Box>
                            ),
                        }}
                        onCellClick={handleCellClick}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        disableRowSelectionOnClick
                    />
                </Box>
            </Grid>}

        </Grid>}
        {selectedIdAlumno && (<>
            <PerfilAlumno idAlumno={selectedIdAlumno}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setSeletedIdAlumno(null);
                        setShowGrid(true);
                    }}
                    style={{ marginBottom: '10px' }} // Add margin-bottom inline style
                >
                    <UndoIcon />&nbsp;Regresar
                </Button>

                <Button variant="contained" color="success" onClick={handleReporteAlumnoClick}>
                    <DescriptionIcon></DescriptionIcon>Reporte</Button>
            </PerfilAlumno>
        </>)}

    </>
    );
}


export default AdminConsultasAlumnos;