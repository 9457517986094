import { Box, Typography } from "@mui/material";

const Copyright = (props) => {
    return(
        <Box position="fixed" bottom={0} width="100%" bgcolor="background.paper" p={1}>
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                Seguimiento a Egresados
            </Typography>
        </Box>
        
    );
}

export default Copyright;