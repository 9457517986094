import { configureStore } from "@reduxjs/toolkit";
import authReducer from '../slices/authSlice';
import { authApi } from "../services/auth.service";
import encuestasReducer from "../slices/encuestaSlice"
import { encuestasApi } from "../services/encuesta.service";
import perfilReducer from "../slices/perfilSlice"
import { perfilApi } from "../services/perfil.service";
import consultaReducer from "../slices/consultaSlice";
import { consultaApi } from "../services/consulta.service";
import consultaEmpresaReducer from "../slices/consultaEmpresaSlice";
import { consultaEmpresaApi } from "../services/consultaEmpresa.service";
import consultaAlumnoReducer from "../slices/consultaAlumnoSlice";
import { consultaAlumnoApi } from "../services/consultaAlumno.service";



const store = configureStore({
    reducer: {
        auth: authReducer,
        encuestas: encuestasReducer,
        perfil: perfilReducer,
        consulta: consultaReducer,
        consultaEmpresa: consultaEmpresaReducer,
        consultaAlumno: consultaAlumnoReducer,
        [authApi.reducerPath]: authApi.reducer,
        [encuestasApi.reducerPath]: encuestasApi.reducer,
        [perfilApi.reducerPath]: perfilApi.reducer,
        [consultaApi.reducerPath]: consultaApi.reducer,
        [consultaEmpresaApi.reducerPath]: consultaEmpresaApi.reducer,
        [consultaAlumnoApi.reducerPath]: consultaAlumnoApi.reducer,
    },
    middleware:(getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(authApi.middleware)
            .concat(encuestasApi.middleware)
            .concat(perfilApi.middleware)
            .concat(consultaApi.middleware)
            .concat(consultaEmpresaApi.middleware)
            .concat(consultaAlumnoApi.middleware),

});

export default store;