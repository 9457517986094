import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

const API_BASE_URL = 'http://51.222.44.69:8022/Dev/Control/';

export const encuestasApi = createApi({
    reducerPath: 'encuestasApi',
    baseQuery:
        fetchBaseQuery({
            baseUrl: API_BASE_URL,
            prepareHeaders: (headers, {getState}) => {
                const token = getState().auth.userInfo.token;
                if (token) {
                    headers.set('authorization', `Bearer ${token}`)
                    return headers;
                }
            },
        }),
    endpoints: (builder) => ({
        verificarEncuesta: builder.query({
            query: ({idCuestionario, idAlumno, idEmpresa}) => (idAlumno ? `alumnos/encuestas/json/alumno/custom/${idAlumno}/${idCuestionario}`
                : `empresas/encuestas/json/empresa/custom/${idEmpresa}/${idCuestionario}`),
            forceRefetch({ state}) {
                return state.encuestas.statusValidar === 'idle';
            },
        }),
        obtenerEncuesta: builder.query({
            query: (idCuestionario) => `cuestionarios/json/custom/${idCuestionario}`,
            forceRefetch({ state}) {
                return state.encuestas.status === 'idle';
            },
        }),
        guardarEncuesta: builder.mutation({
            query: ({url, body}) => ({
                url: url + '/encuestas/save',
                method: 'POST',
                body: body,
            }),
        }),
    }),
});

export const {useObtenerEncuestaQuery, useVerificarEncuestaQuery, useGuardarEncuestaMutation} = encuestasApi;