import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import {
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DescriptionIcon from '@mui/icons-material/Description';
import BallotIcon from '@mui/icons-material/Ballot';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../slices/authSlice";
import { clearRespuestasActuales } from "../slices/encuestaSlice";
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Barras = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const home = {
    title: 'Dashboard',
    url: ''
  }
  const navigate = useNavigate();
  let vlogout = '/';
  let idCuestionario = null

  switch (userInfo.userType) {
    case "X":
      home.url = "admin/dashboard";
      break;
    case "A":
      home.url = "admin/dashboard";
      break;
    case "C":
      home.url = "empresas/dashboard";
      vlogout = '/accesoEmpresas';
      idCuestionario = 7;
      break;
    default:
      home.url = "dashboard";
      idCuestionario = 6;
      break;
  }

  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const Salir = () => {
    dispatch(logout());
    dispatch(clearRespuestasActuales());
    navigate(vlogout);
  };
  return (
    <>
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: "24px", // mantiene el padding cuando la lateral se esta cerrando
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
          </Typography>
          <IconButton color="inherit" onClick={Salir}>
            <ExitToAppIcon /> Salir
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <Link to={home.url} style={{ textDecoration: 'none', color: 'inherit' }}>
            <ListItemButton>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={home.title} />
            </ListItemButton>
          </Link>
          {(userInfo.userType === "A" || userInfo.userType === "X") ? (
            <Link to={"admin/consultas"} style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItemButton>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary="Consultas" />
              </ListItemButton>
            </Link>
          ) : null}
          {(userInfo.userType === "A" || userInfo.userType === "X") ? (
            <Link to={"admin/consultasEmpresas"} style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItemButton>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary="Consulta de empresas" />
              </ListItemButton>
            </Link>
          ) : null}
          {(userInfo.userType === "A" || userInfo.userType === "X") ? (
            <Link to={"admin/consultasAlumnos"} style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItemButton>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary="Consulta de egresados"/>
              </ListItemButton>
            </Link>
          ) : null}
          {(userInfo.userType === "B" || userInfo.userType === "C") ? (
            <Link to="encuesta" state={{ cuestionario: idCuestionario }} style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItemButton>
                <ListItemIcon>
                  <BallotIcon />
                </ListItemIcon>
                <ListItemText primary="Encuesta" />
              </ListItemButton>
            </Link>
          ) : null}
        </List>
      </Drawer>
      <Outlet />
    </>
  );
};

export default Barras;
