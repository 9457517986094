import {useDispatch, useSelector} from "react-redux";
import Seccion from "../components/encuestas/Secciones";
import {Alert, Grid, Typography} from "@mui/material";
import {
    useObtenerEncuestaQuery, useGuardarEncuestaMutation, useVerificarEncuestaQuery
} from "../services/encuesta.service";
import {setRealizoEncuesta, setSeccionActual} from '../slices/encuestaSlice';
import Spinner from "../components/Spinner";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";

const Encuesta = () => {
    const navigate = useNavigate();
    let {state} = useLocation();
    const dispatch = useDispatch();
    const {
        encuestaActual, respuestasActuales, realizoEncuesta, esEditable,  fechaRealizoEcuesta, status, statusValidar, error
    } = useSelector((state) => state.encuestas);
    const [idCuestionario, setIdCuestionario] = useState(state?.cuestionario || '');

    useEffect(() => {
        if (!idCuestionario) {
            if (!encuestaActual?.idCuestionario) {
                navigate('/dashboard');
            } else {
                setIdCuestionario(encuestaActual.idCuestionario)
            }
        }
    }, [idCuestionario, encuestaActual?.idCuestionario, navigate]);

    const {userInfo} = useSelector((state) => state.auth);

    let body = {
        idCuestionario: idCuestionario,
        idPeriodo: userInfo.currentP,
        idEstatus: 16
    };

    let url;
    let api;
    if (userInfo && userInfo.userType === "B") {
        url = "/dashboard";
        api = "alumnos";
        body.idAlumno = userInfo.userId;
        body.idAlumnoCuestionario = null;
        // body.idAlumnoCuestionario = idResultadoCuestionario

    } else if (userInfo && userInfo.userType === "X") {
        url = "/admin/dashboard";
    } else if (userInfo && userInfo.userType === "C") {
        url = "/empresas/dashboard";
        api = "empresas";
        body.idEmpresa = userInfo.userId;
        body.idEmpresaCuestionario = null;
        // body.idEmpresaCuestionario = idResultadoCuestionario
    }
    useVerificarEncuestaQuery({idCuestionario: idCuestionario, idAlumno: userInfo.userType === "B" ? userInfo.userId : null, idEmpresa: userInfo.userType === "C" ? userInfo.userId : null})

    const [guardarEncuesta] = useGuardarEncuestaMutation();
    const onSubmit = async (data, event) => {
        event.preventDefault();
        event.stopPropagation();
        if(!esEditable) return;

        let respuestas = Object.values(respuestasActuales).map(value => {
            if (value.idCuestionarioPregunta) {
                return {
                    idCuestionario: idCuestionario,
                    idCuestionarioPregunta: value.idCuestionarioPregunta,
                    idCuestionarioRespuesta: value.idCuestionarioRespuesta,
                    comentario: value.comentario,
					tipo: value.tipo
                }
            } else {
                return Object.values(value).map(nested => ({
                    idCuestionario: idCuestionario,
                    idCuestionarioPregunta: nested.idCuestionarioPregunta,
                    idCuestionarioRespuesta: nested.idCuestionarioRespuesta,
                    comentario: nested.comentario,
					tipo: nested.tipo
                }));
            }

        }).flat();

        if (userInfo && userInfo.userType === "B") {
            body.alumnosCuestionariosPreguntas = respuestas;
        } else if (userInfo && userInfo.userType === "C") {
            body.empresasCuestionariosPreguntas = respuestas;
        }

        const result = await guardarEncuesta({url: api, body: body});
        //encuesta guardada, se genero su id, regresar
        if (result.data.id) {
            dispatch(setSeccionActual(0))
            dispatch(setRealizoEncuesta(result.data.id));
            navigate(url);
        }
    };

    const CuestionarioLoader = (props) => {
        useObtenerEncuestaQuery(idCuestionario);
        return;
    };

    return (statusValidar === 'succeeded' ? <>
            <CuestionarioLoader/>
            {status === "loading" ?
                <Grid container justifyContent="center" alignItems="center" direction="column" mt={9}>
                    <Spinner/>
                </Grid> : ""}
            {status === "failed" ?
                <Grid container justifyContent="center" alignItems="center" direction="column" mt={9}>
                    <Alert severity="error">
                        <Typography>
                            {error}
                        </Typography>
                    </Alert>
                </Grid> : ""}
            {status === "succeeded" ? <Grid
                container
                spacing={0}
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                mt={8}
                mb="30px"
                sx={{textAlign: 'center'}}
            >
                <Grid item>
                    {realizoEncuesta && (
                        <Typography variant="h6" style={{
                            fontWeight: 'bold', color: "#1976d2", marginBottom: '0', paddingTop: '5px'
                        }}> Ya contestaste la encuesta el día {fechaRealizoEcuesta}
                        </Typography>
                    )}
                    <Typography variant="h5" component="h5" style={{
                        fontWeight: 'bold', color: '#333', marginBottom: '0', paddingTop: '10px'
                    }}>
                        {encuestaActual.clave} - {encuestaActual.cuestionario}
                    </Typography>
                </Grid>
                <Grid item>
                    <Seccion onSubmit={onSubmit}/>
                </Grid>

            </Grid> : ""}
        </> : (
            <Grid container justifyContent="center" alignItems="center" direction="column" mt={9}> <Spinner/> </Grid>)

    );
};

export default Encuesta;
