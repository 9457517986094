import * as React from "react";
import {useSelector, useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import Preguntas from "./Preguntas";
import Typography from "@mui/material/Typography";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import ReplayIcon from '@mui/icons-material/Replay';
import {setSeccionActual} from '../../slices/encuestaSlice';

export default function Seccion({onSubmit}) {
    const dispatch = useDispatch();
    const {encuestaActual, seccionActual, esEditable} = useSelector((state) => state.encuestas);
    const secciones = encuestaActual.secciones;

    const [activeStep, setActiveStep] = React.useState(seccionActual);
    const {register, handleSubmit, formState} = useForm({});

    const handleNext = () => {
        handleSubmit((data) => {
            if (!formState.errors.length) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                dispatch(setSeccionActual(activeStep + 1))
                window.scrollTo(0, 0);
            }
        })();
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        dispatch(setSeccionActual(activeStep - 1))
        window.scrollTo(0, 0);
    };

    const handleReset = () => {
        setActiveStep(0);
        dispatch(setSeccionActual(0))
        window.scrollTo(0, 0);
    };

    return (<Box sx={{width: "100%", flexGrow: 1}}>

        <Typography fontWeight="bold" padding='10px'>
            {secciones[activeStep].numero} - {secciones[activeStep].cuestionarioSeccion}
        </Typography>

        <MobileStepper
            variant="progress"
            steps={secciones.length}
            position="static"
            activeStep={activeStep}
            style={{flexDirection: "column"}}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{width: '100%', textAlign: 'justify'}}>
                {<Preguntas preguntas={secciones[activeStep].preguntas} register={register} formState={formState}/>}
            </Box>
            {(activeStep === secciones.length - 1) && esEditable && (<Typography sx={{mt: 2, mb: 1}}>
                Última sección de la encuesta - A continuación, guarda tus respuestas.
            </Typography>)}
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    <KeyboardArrowLeft/>
                    Atrás
                </Button>
                {activeStep === secciones.length - 1 ? (<>
                    <Box sx={{flex: '1 1 auto'}}/>
                    <Button onClick={handleReset}> <ReplayIcon/>Reiniciar</Button>
                    <Box sx={{flex: '1 1 auto'}}/>
                    {esEditable && (<Button type="submit" size="small">
                        {'Guardar'}
                        <KeyboardArrowRight/>
                    </Button>)}
                </>) : (<>
                    <Box sx={{flex: '1 1 auto'}}/>
                    <Button size="small" onClick={handleNext}>
                        {'Siguiente'}
                        <KeyboardArrowRight/>
                    </Button>

                </>)}
            </Box>
        </form>
    </Box>);
}
