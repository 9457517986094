import { createSlice } from '@reduxjs/toolkit';
import { consultaApi } from '../services/consulta.service';

const initialState = {
    statusCarrera: 'idle',
    statusEgresado: 'idle',
    statusDetalles: 'idle',
    statusAnio: 'idle',
    carreras: [],
    egresados: [],
    detalles: [],
};

const consultaSlice = createSlice({
    name: 'consulta',
    initialState,
    reducers: {
        reloadEgresado: (state) => {
            state.statusEgresado = 'idle';
            state.statusDetalles = 'idle';
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(consultaApi.endpoints.obtenerCarrera.matchPending, (state) => {
                state.statusCarrera = 'loading';
            })
            .addMatcher(
                consultaApi.endpoints.obtenerCarrera.matchFulfilled,
                (state, { payload }) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusCarrera = 'succeeded';
                    state.carreras = payload;
                }
            )
            .addMatcher(consultaApi.endpoints.obtenerCarrera.matchRejected, (state, { payload }) => {
                state.statusCarrera = 'failed';
            })
            .addMatcher(
                consultaApi.endpoints.obtenerEgresado.matchPending,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusEgresado = 'loading';
                    state.egresados = [];
                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerEgresado.matchFulfilled,
                (state, { payload }) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusEgresado = 'succeeded';
                    state.egresados = payload;
                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerEgresado.matchRejected,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusEgresado = 'failed';
                    state.egresados = [];
                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerEgresadoGeneracion.matchPending,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusEgresado = 'loading';
                    state.egresados = [];
                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerEgresadoGeneracion.matchFulfilled,
                (state, { payload }) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusEgresado = 'succeeded';
                    state.egresados = payload;
                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerEgresadoGeneracion.matchRejected,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusEgresado = 'failed';
                    state.egresados = [];

                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerDetalles1.matchPending,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'loading';
                    state.detalles = [];
                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerDetalles1.matchFulfilled,
                (state, { payload }) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'succeeded';
                    state.detalles = payload;
                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerDetalles1.matchRejected,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'failed';
                    state.detalles = [];
                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerDetalles2.matchPending,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'loading';
                    state.detalles = [];
                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerDetalles2.matchFulfilled,
                (state, { payload }) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'succeeded';
                    state.detalles = payload;

                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerDetalles2.matchRejected,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'failed';
                    state.detalles = [];

                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerAnio.matchPending,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusAnio = 'loading';
                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerAnio.matchFulfilled,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusAnio = 'succeeded';
                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerAnio.matchRejected,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusAnio = 'failed';

                }
            )
            .addMatcher(
                consultaApi.endpoints.reporteDetalles.matchPending,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'loading';
                    state.detalles = [];
                }
            )
            .addMatcher(
                consultaApi.endpoints.reporteDetalles.matchFulfilled,
                (state, { payload }) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'succeeded';
                    state.detalles = payload;

                }
            )
            .addMatcher(
                consultaApi.endpoints.reporteDetalles.matchRejected,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'failed';
                    state.detalles = [];

                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerDetallesEncuesta.matchPending,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'loading';
                    state.detalles = [];
                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerDetallesEncuesta.matchFulfilled,
                (state, { payload }) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'succeeded';
                    state.detalles = payload;
                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerDetallesEncuesta.matchRejected,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'failed';
                    state.detalles = [];
                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerDetallesEncuesta2.matchPending,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'loading';
                    state.detalles = [];
                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerDetallesEncuesta2.matchFulfilled,
                (state, { payload }) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'succeeded';
                    state.detalles = payload;

                }
            )
            .addMatcher(
                consultaApi.endpoints.obtenerDetallesEncuesta2.matchRejected,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'failed';
                    state.detalles = [];

                }
            )

    },
});

export const {
    reloadEgresado
} = consultaSlice.actions;

export default consultaSlice.reducer;