import { Alert, Grid, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

const Unauthorized = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <Alert variant="outlined" severity="error">
          No está autorizado para ver esta página
        </Alert>
        <Typography variant="h5">
          Si usted tiene usuario y contraseña puede acceder en{" "}
          <NavLink to="/">Login</NavLink>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Unauthorized;
