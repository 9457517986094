import { Paper, Grid, Container, Typography, Alert, Button } from "@mui/material";
import { useSelector } from "react-redux";
import Spinner from "../components/Spinner";
import React from "react";
import { useVerificarEncuestaQuery } from "../services/encuesta.service";
import { useNavigate } from "react-router-dom";

const DashboardEmpresas = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { statusValidar, realizoEncuesta, error } = useSelector((state) => state.encuestas);
  useVerificarEncuestaQuery({ idCuestionario: 7, idEmpresa: userInfo.userId })

  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }} style={{ minHeight: "100vh", backgroundColor: '#f5f5f5' }}>
      <Grid container spacing={3} style={{ marginTop: '64px', marginLeft: '10px' }}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240,
          }}
        >
          <Typography variant="h4">
            Bienvenido {userInfo.userName}
          </Typography>
          {statusValidar === "loading" ?
            <Grid container justifyContent="center" alignItems="center" direction="column" mt={9}>
              <Spinner />
            </Grid> : (<>
              {realizoEncuesta ? (<Typography variant="p">
                Encuesta realizada, gracias!.
              </Typography>) : (<Typography variant="p">
                Agradecemos te tomes el tiempo para contestar la encuesta.
              </Typography>)}
            </>)}
          {
            !realizoEncuesta && <Grid item ml={2}>
              <Button variant="contained" color="primary" onClick={() => {
                navigate('/encuesta', { state: { cuestionario: 7 } })
              }}>
                Contestar Encuesta
              </Button>
            </Grid>
          }
          {
            realizoEncuesta && <Grid item ml={2}>
              <Button variant="contained" color="primary" onClick={() => {
                navigate('/encuesta', { state: { cuestionario: 7 } })
              }}>
                Actualizar Datos en la Encuesta
              </Button>
            </Grid>
          }

          {statusValidar === "failed" ?
            <Grid container justifyContent="center" alignItems="center" direction="column" mt={9}>
              <Alert severity="error">
                <Typography>
                  {error}
                </Typography>
              </Alert>
            </Grid>
            : ""}
        </Paper>
      </Grid>
    </Container>
  );
};

export default DashboardEmpresas;
