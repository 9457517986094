import { createSlice } from '@reduxjs/toolkit';
import { consultaEmpresaApi } from '../services/consultaEmpresa.service';

const initialState = {
    statusCarrera: 'idle',
    statusEmpresa: 'idle',
    statusDetalles: 'idle',
    carreras: [],
    egresados: [],
    detalles: [],
};

const consultaEmpresaSlice = createSlice({
    name: 'consultaEmpresa',
    initialState,
    reducers: {
        reloadEmpresa: (state) => {
            state.statusEmpresa = 'idle';
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                consultaEmpresaApi.endpoints.obtenerEmpresa.matchPending,
                (state) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusEmpresa = 'loading';
                }
            )
            .addMatcher(
                consultaEmpresaApi.endpoints.obtenerEmpresa.matchFulfilled,
                (state, { payload }) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusEmpresa = 'succeeded';
                }
            )
            .addMatcher(
                consultaEmpresaApi.endpoints.obtenerEmpresa.matchRejected,
                (state, { payload }) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusEmpresa = 'failed';

                }
            )
            .addMatcher(
                consultaEmpresaApi.endpoints.reporteDetalles.matchPending,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'loading';
                }
            )
            .addMatcher(
                consultaEmpresaApi.endpoints.reporteDetalles.matchFulfilled,
                (state, { payload }) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'succeeded';
                    state.detalles = payload;

                }
            )
            .addMatcher(
                consultaEmpresaApi.endpoints.reporteDetalles.matchRejected,
                (state, action) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusDetalles = 'failed';

                }
            )

    },
});

export const {
    reloadEgresado
} = consultaEmpresaSlice.actions;

export default consultaEmpresaSlice.reducer;