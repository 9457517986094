import React from 'react';
import Respuestas from "./Respuestas";
import {Box, Typography} from "@mui/material";
import {useSelector} from "react-redux";

const Preguntas = ({preguntas, parentId, preguntasOcultas, register, formState }) => {
    const {respuestasActuales} = useSelector((state) => state.encuestas);

    const obtenerPreguntasOcultas = (preguntas) => {
        return preguntas.flatMap(pregunta =>
            pregunta.respuestas.filter(respuesta =>
                respuesta.habilitarPreguntas
            ));
    }
    const idsPreguntasDependientes =  preguntasOcultas?.flatMap(respuesta => respuesta.habilitarPreguntas )?.map(r => r.idCuestionarioPregunta);
    const idsPreguntasSeleccionadas = preguntasOcultas?.find( r => r.idCuestionarioRespuesta === respuestasActuales[parentId]?.idCuestionarioRespuesta)?.habilitarPreguntas?.map(r => r.idCuestionarioPregunta)

    return (
        <Box sx={{width: "100%"}}>
            {preguntas
                .filter(pregunta => (
                        !(
                            idsPreguntasDependientes?.includes(pregunta.idCuestionarioPregunta) && //esta por defecto oculta
                            !idsPreguntasSeleccionadas?.includes(pregunta.idCuestionarioPregunta)  //a menos que no esta habilitada por respuesta del padre
                        )
                    )
                )
                .map((pregunta) => (
                    <Box key={pregunta.idCuestionarioPregunta}
                         style={{marginBottom: '10px', border: '1px solid #1976d2'}}
                         component="fieldset">
                        <Typography fontWeight={pregunta.requerido ? "bold" : "normal"}>
                            {pregunta.cuestionarioPregunta}
                        </Typography>
                        {(!(pregunta.preguntas?.length && pregunta.tipo === 'A'))? <Respuestas respuestas={pregunta.respuestas} tipo={pregunta.tipo}
                                     preguntaId={pregunta.idCuestionarioPregunta} register={register} formState={formState} requerido={pregunta.requerido} />
                        : ''}
                        {pregunta.preguntas ?
                            <Box pt={2} pl={2}>
                                <Preguntas preguntas={pregunta.preguntas} parentId={pregunta.idCuestionarioPregunta}
                                           preguntasOcultas={obtenerPreguntasOcultas(preguntas)} register={register} formState={formState} />
                            </Box>
                            : ''}
                    </Box>
                ))}
        </Box>
    );
};

export default Preguntas;
