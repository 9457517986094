import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Unauthorized from "../components/Unauthorized";

const ProtectedRoute = () => {
    const { userInfo } = useSelector((state) => state.auth)
    if(!userInfo){
        return(<Unauthorized />)
    }
    return <Outlet />
};

export default ProtectedRoute;