import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../helpers/apiCall";

export const reporteEmpresa = createAsyncThunk(
    'cuestionario/reporteEmpresa',
    async ({ token }, { rejectWithValue }) => {
        try {

            const { data } = await apiCall(`cuestionarios/reportes/Nw/resultado_cuestionario_2023.xlsx`, {}, { 'Authorization': 'Bearer ' + token }, 'GET', 'arraybuffer');
            return btoa(
                new Uint8Array(data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            )
        } catch (error) {
            if (error.response && error.response.data.error) {
                return rejectWithValue(error.response.data.error)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

export const concentradoDetalle = createAsyncThunk(
    'cuestionario/reporte',
    async ({ idCuestionario, anio, token }, { rejectWithValue }) => {
        try {

            const { data } = await apiCall(`seguimiento/egresados/reportes/seguimiento_concentrado_empresas.xlsx`, {}, { 'Authorization': 'Bearer ' + token }, 'GET', 'arraybuffer');
            return btoa(
                new Uint8Array(data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            )
        } catch (error) {
            if (error.response && error.response.data.error) {
                return rejectWithValue(error.response.data.error)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);