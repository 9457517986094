import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../helpers/apiCall";

export const reporteDetalle = createAsyncThunk(
    'cuestionario/reporte',
    async ({ idCuestionario, anio, token }, { rejectWithValue }) => {
        try {

            const { data } = await apiCall(`cuestionarios/reportes/${btoa(idCuestionario)}/resultado_cuestionario_${anio}.xlsx`, {}, { 'Authorization': 'Bearer ' + token }, 'GET', 'arraybuffer');
            return btoa(
                new Uint8Array(data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            )
        } catch (error) {
            if (error.response && error.response.data.error) {
                return rejectWithValue(error.response.data.error)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

export const concentradoDetalle = createAsyncThunk(
    'cuestionario/reporte',
    async ({ idCuestionario, anio, token }, { rejectWithValue }) => {
        try {

            const { data } = await apiCall(`seguimiento/egresados/reportes/seguimiento_concentrado.xlsx`, {}, { 'Authorization': 'Bearer ' + token }, 'GET', 'arraybuffer');
            return btoa(
                new Uint8Array(data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            )
        } catch (error) {
            if (error.response && error.response.data.error) {
                return rejectWithValue(error.response.data.error)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

export const reporteAlumno = createAsyncThunk(
    'cuestionario/reporte',
    async ({ idAlumno,idCuestionario, token }, { rejectWithValue }) => {
        try {

            const { data } = await apiCall(`alumnos/encuestas/reportes/${idAlumno}/${idCuestionario}//encuesta.pdf`, {}, { 'Authorization': 'Bearer ' + token }, 'GET', 'arraybuffer');
            return btoa(
                new Uint8Array(data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            )
        } catch (error) {
            if (error.response && error.response.data.error) {
                return rejectWithValue(error.response.data.error)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);