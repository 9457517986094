import { Alert, AlertTitle } from "@mui/material";

const Error = ({ children, ...props }) => {
    return (
        <Alert severity="error" {...props} sx={{ mb: 5 }}>
            <AlertTitle>Error</AlertTitle>
            {children}
        </Alert>
    )
}

const Advertencia = ({ children, ...props }) => {
    return (
        <Alert severity="warning" {...props} sx={{ mb: 5 }}>
            <AlertTitle></AlertTitle>
            {children}
        </Alert>
    )
}

export {Error, Advertencia};