import { createSlice } from '@reduxjs/toolkit';
import { consultaAlumnoApi } from '../services/consultaAlumno.service';

const initialState = {
    statusAlumno: 'idle',
    statusEmpresa: 'idle',
    statusDetalles: 'idle',
    alumnos: [],
};

const consultaAlumnoSlice = createSlice({
    name: 'consultaAlumno',
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(
                consultaAlumnoApi.endpoints.obtenerAlumno.matchPending,
                (state) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusAlumno = 'loading';
                }
            )
            .addMatcher(
                consultaAlumnoApi.endpoints.obtenerAlumno.matchFulfilled,
                (state, { payload }) => {
                    // Actualizar el estado con los datos del alumno obtenidos\
                    state.alumnos = payload;
                    state.statusAlumno = 'succeeded';
                }
            )
            .addMatcher(
                consultaAlumnoApi.endpoints.obtenerAlumno.matchRejected,
                (state, { payload }) => {
                    // Actualizar el estado con los datos del alumno obtenidos
                    state.statusAlumno = 'failed';

                });

    },
});

export default consultaAlumnoSlice.reducer;