import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const API_BASE_URL = 'http://51.222.44.69:8022/Dev/Control/';

export const consultaEmpresaApi = createApi({
    reducerPath: 'consultaEmpresaApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({

        obtenerEmpresa: build.query({
            query: ({ fechaInicio, fechaFinal }) => `seguimiento/egresados/json/list/empresas?fechaInicio=${fechaInicio}&fechaFinal=${fechaFinal}`,
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
        }),
        reporteDetalles: build.query({
            query: () => `cuestionarios/reportes/Nw/resultado_cuestionario_2023.xlsx`,
            // Solo tenga una entrada de caché porque el argumento siempre se asigna a una cadena
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
        }),
    }),
});
export const { useObtenerEmpresaQuery, useReporteDetallesQuery } = consultaEmpresaApi;