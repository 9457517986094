import {createSlice} from '@reduxjs/toolkit';
import {encuestasApi} from '../services/encuesta.service';

const initialState = {
    encuestaActual: null,
    respuestasActuales: JSON.parse(localStorage.getItem('respuestasActuales')) || {},
    seccionActual: parseInt(localStorage.getItem('seccionActual') || 0),
    status: 'idle',
    statusValidar: 'idle',
    realizoEncuesta: true,
    idResultadoCuestionario: null,
    esEditable: true,
    fechaRealizoEcuesta: null,
    error: null,
};

const encuestasSlice = createSlice({
    name: 'encuestas', initialState, reducers: {
        setEncuestaActual: (state, {payload}) => {
            state.encuestaActual = payload;
        }, setRespuestasActuales: (state, {payload}) => {
            state.respuestasActuales[payload.idCuestionarioPregunta] = payload;
            localStorage.setItem('respuestasActuales', JSON.stringify(state.respuestasActuales));
        }, setRespuestasActualesMultiples: (state, {payload}) => {
            let data = state.respuestasActuales[payload.idCuestionarioPregunta] || {};
            if (payload.checked) {
                data[payload.idCuestionarioRespuesta] = payload
            } else {
                delete data[payload.idCuestionarioRespuesta];
            }
            state.respuestasActuales[payload.idCuestionarioPregunta] = data;
            localStorage.setItem('respuestasActuales', JSON.stringify(state.respuestasActuales));
        }, setSeccionActual: (state, {payload}) => {
            state.seccionActual = parseInt(payload);
            localStorage.setItem('seccionActual', parseInt(payload));
        }, setRealizoEncuesta: (state, {payload}) => {
            state.realizoEncuesta = true;
            state.idResultadoCuestionario = payload;
            state.statusValidar = 'idle';
        }, clearRespuestasActuales: (state) => {
            state.encuestaActual = null;
            state.respuestasActuales = {};
            localStorage.removeItem('respuestasActuales');
            state.seccionActual = 0;
            localStorage.removeItem('seccionActual');
            state.realizoEncuesta = true;
            state.statusValidar = 'idle';
            state.status = 'idle';

        },
    }, extraReducers: (builder) => {
        builder
            .addMatcher(encuestasApi.endpoints.verificarEncuesta.matchPending, (state) => {
                state.statusValidar = 'loading';
            })
            .addMatcher(encuestasApi.endpoints.verificarEncuesta.matchFulfilled, (state, {payload}) => {
                state.statusValidar = 'succeeded';
                if (payload.idAlumnoCuestionario || payload.idEmpresaCuestionario) {
                    state.realizoEncuesta = true;
                    state.idResultadoCuestionario = payload.idAlumnoCuestionario || payload.idEmpresaCuestionario;
                    let fecha = payload.fecha?.split(" ");
                    state.fechaRealizoEcuesta = fecha[0] + " a las " + fecha[1].substr(0,5)  + " " +fecha[2]
                    //ya no dejar que guarde las modificaciones si no ha guardado la encuesta editada
                    //if (!localStorage.getItem('respuestasActuales')) {
                        let respuestas = payload.empresasCuestionariosPreguntas || payload.alumnosCuestionariosPreguntas;
                        //limpiar objecto
                        respuestas.forEach((objeto, indice) => {
                            for (let propiedad in objeto) {
                                if (objeto[propiedad] === 0) {
                                    delete objeto[propiedad];
                                }
                            }
                            respuestas[indice] = objeto;
                        });

                        //convertir array a objecto
                        respuestas = respuestas.reduce((acc, curr, index) => {
                            if (!acc.hasOwnProperty(curr.idCuestionarioPregunta)) {
                                //respuesta unica
                                acc[curr.idCuestionarioPregunta] = curr;
                            } else {
                                if (acc[curr.idCuestionarioPregunta].idCuestionarioRespuesta != null) {
                                    //primera respuesta multiple
                                    let temp = acc[curr.idCuestionarioPregunta];
                                    acc[curr.idCuestionarioPregunta] = {
                                        [temp.idCuestionarioRespuesta]: temp
                                    };
                                    acc[curr.idCuestionarioPregunta][curr.idCuestionarioRespuesta] = curr;
                                } else {
                                    //respuesta multiple ya existentes
                                    acc[curr.idCuestionarioPregunta][curr.idCuestionarioRespuesta] = curr;
                                }

                            }
                            return acc;
                        }, {});
                        //setear respuestas actuales
                        state.respuestasActuales = respuestas;
                        localStorage.setItem('respuestasActuales', JSON.stringify(state.respuestasActuales));

                    //}
                } else {
                    state.realizoEncuesta = false;
                    state.idResultadoCuestionario = null;
                }
            })
            .addMatcher(encuestasApi.endpoints.verificarEncuesta.matchRejected, (state, {payload}) => {
                state.statusValidar = 'failed';
                state.error = payload.data?.error;
            })
            .addMatcher(encuestasApi.endpoints.obtenerEncuesta.matchPending, (state) => {
                state.status = 'loading';
            })
            .addMatcher(encuestasApi.endpoints.obtenerEncuesta.matchFulfilled, (state, action) => {
                state.status = 'succeeded';
                state.encuestaActual = action.payload;
                state.encuestaActual.idCuestionario = action.meta.arg.originalArgs;
            })
            .addMatcher(encuestasApi.endpoints.obtenerEncuesta.matchRejected, (state, {payload}) => {
                state.status = 'failed';
                state.error = payload.data?.error;
            })
            .addMatcher(encuestasApi.endpoints.guardarEncuesta.matchPending, (state) => {
                state.status = 'loading';
            })
            .addMatcher(encuestasApi.endpoints.guardarEncuesta.matchFulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addMatcher(encuestasApi.endpoints.guardarEncuesta.matchRejected, (state, {payload}) => {
                state.status = 'failed';
                state.error = payload.data.error;
            });
    },
});

export const {
    setEncuestaActual,
    setRespuestasActuales,
    setRespuestasActualesMultiples,
    setSeccionActual,
    clearRespuestasActuales,
    setRealizoEncuesta
} = encuestasSlice.actions;

export default encuestasSlice.reducer;
