import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const API_BASE_URL = 'http://51.222.44.69:8022/Dev/Control/';
export const perfilApi = createApi({
    reducerPath: 'perfilApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        obtenerAlumno: build.query({
            query: (idAlumno) => `alumnos/json/${idAlumno}`
        }),
    }),
});
export const {useObtenerAlumnoQuery} = perfilApi;