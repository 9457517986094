import axios from "axios";

const baseURL = `http://51.222.44.69:8022/Dev/Control/`

export const apiCall = (url, data, headers, method, responseType = 'json') => axios({
    method,
    url: baseURL + url,
    data,
    headers,
    responseType
});