import React, {Fragment} from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import {useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {setRespuestasActuales, setRespuestasActualesMultiples} from '../../slices/encuestaSlice';
import {Alert, Box, Checkbox, Typography} from "@mui/material";

const Respuestas = ({respuestas, tipo, preguntaId, register, formState: {errors}, requerido}) => {
    const {respuestasActuales, esEditable} = useSelector((state) => state.encuestas);
    const dispatch = useDispatch();
    const [value, setValue] = useState(respuestasActuales[preguntaId]?.idCuestionarioRespuesta || '');
    const [textFieldValue, setTextFieldValue] = useState(respuestasActuales[preguntaId]?.comentario || '');

    const [checkboxValues, setCheckboxValues] = useState(respuestasActuales[preguntaId] || {});
    const [textFieldValues, setTextFieldValues] = useState(respuestasActuales[preguntaId] || {});
    const handleChange = (event) => {
        if(!esEditable) return;
        setValue(event.target.value);
        let respuesta = respuestas.find(e => e.idCuestionarioRespuesta === parseInt(event.target.value));
        dispatch(setRespuestasActuales({
            idCuestionarioPregunta: respuesta.idCuestionarioPregunta,
            idCuestionarioRespuesta: respuesta.idCuestionarioRespuesta,
			tipo: tipo
        }))
        setTextFieldValue('');

        //todo se debe eliminar todas las preguntas hijas recursivas que no sonde esa respuesta
        //habilitarPreguntas: respuesta.habilitarPreguntas.map(e => e.idCuestionarioPregunta),
    }
    const handleTextChange = (event) => {
        if(!esEditable) return;
        setTextFieldValue(event.target.value);
        dispatch(setRespuestasActuales({
            idCuestionarioPregunta: preguntaId,
            idCuestionarioRespuesta: respuestasActuales[preguntaId]?.idCuestionarioRespuesta,
            comentario: event.target.value,
			tipo: tipo
        }))
    };

    const handleCheckboxChange = (event) => {
        if(!esEditable) return;
        const {checked, dataset} = event.target;
        if(checked){
            setCheckboxValues({...checkboxValues, [dataset.idrespuesta]: {checked: checked}});
        } else {
            delete checkboxValues[dataset.idrespuesta];
            setCheckboxValues(checkboxValues);
        }

        dispatch(setRespuestasActualesMultiples({
            idCuestionarioPregunta: preguntaId,
            idCuestionarioRespuesta: parseInt(dataset.idrespuesta),
            comentario: null,
			tipo: tipo,
            checked: checked
        }))
        setTextFieldValues({...textFieldValues, [dataset.idrespuesta]: {comentario: null}});
    };

    const handleTextValuesChange = (event) => {
        if(!esEditable) return;
        const {value, dataset} = event.target;
        setTextFieldValues({...textFieldValues, [dataset.idrespuesta]: {comentario: value}});
        dispatch(setRespuestasActualesMultiples({
            idCuestionarioPregunta: preguntaId,
            idCuestionarioRespuesta: parseInt(dataset.idrespuesta),
            comentario: event.target.value,
			tipo: tipo,
            checked: Boolean(event.target.value)
        }))
    };

    let id = 'respuesta.pregunta.' + preguntaId;
    if (tipo === 'C') {

        return (<FormControl component="fieldset">
            <RadioGroup
                row
                value={value}
                onChange={handleChange}
            >
                {respuestas.map((respuesta) => {
                    let childId = 'observacion.' + respuesta.idCuestionarioRespuesta;
                    return (<Fragment key={preguntaId + '-' + respuesta.idCuestionarioRespuesta}>
                        <FormControlLabel
                            key={'respuesta.' + respuesta.idCuestionarioRespuesta}
                            value={respuesta.idCuestionarioRespuesta}
                            control={<Radio/>}
                            {...register(id, {required: "Seleccione una opcion"})}
                            label={respuesta.cuestionarioRespuesta + (respuesta.observacion ? ' (' + respuesta.observacion + ')' : '')}
                        />
                        {respuesta.observacion && respuestasActuales[preguntaId]?.idCuestionarioRespuesta === respuesta.idCuestionarioRespuesta && (<>
                            <Box sx={{width: '100%'}}>
                                <TextField
                                    key={childId}
                                    label="Escriba aquí"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={textFieldValue}
                                    {...register(childId, {
                                        required: "Ingrese un dato valido", maxLength: {
                                            value: 1500, message: "El campo no debe tener más de 1500 caracteres"
                                        }, onChange: handleTextChange
                                    })}
                                />
                            </Box>
                            {errors?.observacion && errors.observacion[respuesta.idCuestionarioRespuesta] &&
                                <Alert severity="error">
                                    <Typography>
                                        {errors.observacion[respuesta.idCuestionarioRespuesta].message}
                                    </Typography>
                                </Alert>}
                        </>)}
                    </Fragment>)
                })}
            </RadioGroup>
            {errors?.respuesta?.pregunta[preguntaId] && <Alert severity="error">
                <Typography>
                    {errors?.respuesta?.pregunta[preguntaId].message}
                </Typography>
            </Alert>}
        </FormControl>);
    } else if (tipo === 'A') {
        return (<><TextField
            id={id}
            sx={{width: '100%'}}
            value={textFieldValue}
            {...register('respuesta.pregunta.' + preguntaId, {
                ...(requerido && {required: "Ingrese un dato valido"}),
                maxLength: {value: 1500, message: "El campo no debe tener más de 1500 caracteres"},
                onChange: handleTextChange
            })}
        />
            {errors?.respuesta?.pregunta[preguntaId] && <Alert severity="error">
                <Typography>
                    {errors?.respuesta?.pregunta[preguntaId].message}
                </Typography>
            </Alert>}
        </>);
    } else if (tipo === 'M') {
        return (<>
            {respuestas.map((respuesta) => {
                let childId = 'observacion.' + respuesta.idCuestionarioRespuesta;
                return (<Fragment key={preguntaId + '-' + respuesta.idCuestionarioRespuesta}>
                    <FormControlLabel sx={{display: 'inline-block'}}
                                      key={'respuesta.' + respuesta.idCuestionarioRespuesta}
                                      checked={Boolean(checkboxValues[respuesta.idCuestionarioRespuesta]) || Boolean(checkboxValues.idCuestionarioRespuesta === respuesta.idCuestionarioRespuesta)}
                                      {...register(id, {
                                          required: "Seleccione una opcion", onChange: handleCheckboxChange
                                      })}
                                      control={<Checkbox
                                          inputProps={{"data-idrespuesta": respuesta.idCuestionarioRespuesta}}/>}
                                      label={respuesta.cuestionarioRespuesta + (respuesta.observacion ? ' (' + respuesta.observacion + ')' : '')}
                    />
                    {respuesta.observacion && (Boolean(checkboxValues[respuesta.idCuestionarioRespuesta]) || Boolean(checkboxValues.idCuestionarioRespuesta === respuesta.idCuestionarioRespuesta))&& (<>
                        <Box sx={{width: '100%'}}>
                            <TextField
                                key={childId}
                                label="Escriba aquí"
                                size="small"
                                sx={{width: '100%'}}
                                value={textFieldValues[respuesta.idCuestionarioRespuesta]?.comentario || textFieldValues.comentario || ''}
                                inputProps={{"data-idrespuesta": respuesta.idCuestionarioRespuesta}}
                                {...register(childId, {
                                    required: "Ingrese un dato valido", maxLength: {
                                        value: 1500, message: "El campo no debe tener más de 1500 caracteres"
                                    }, onChange: handleTextValuesChange
                                })}

                            />
                        </Box>
                        {errors?.observacion && errors.observacion[respuesta.idCuestionarioRespuesta] &&
                            <Alert severity="error">
                                <Typography>
                                    {errors.observacion[respuesta.idCuestionarioRespuesta].message}
                                </Typography>
                            </Alert>}
                    </>)}
                </Fragment>)
            })}
            {errors?.respuesta?.pregunta[preguntaId] && <Alert severity="error">
                <Typography>
                    {errors?.respuesta?.pregunta[preguntaId].message}
                </Typography>
            </Alert>}
        </>);
    } else if (tipo === 'W') {
        return (<>
            {respuestas.map((respuesta) => {
                let childId = 'observacion.' + respuesta.idCuestionarioRespuesta;
                return (<Fragment key={preguntaId + '-' + respuesta.idCuestionarioRespuesta}>
                    <FormControlLabel
                        sx={{display: respuesta.observacion ? 'flex-inline' : 'inline-block', alignItems: 'flex-start'}}
                        key={'respuesta.' + respuesta.idCuestionarioRespuesta}
                        control={(respuesta.observacion) ? (<>
                            <TextField
                                key={childId}
                                label="Escriba aquí"
                                size="small"
                                value={textFieldValues[respuesta.idCuestionarioRespuesta]?.comentario  || textFieldValues.comentario  || ''}
                                inputProps={{"data-idrespuesta": respuesta.idCuestionarioRespuesta}}
                                {...register(childId, {
                                    required: "Ingrese un dato valido", maxLength: {
                                        value: 1500, message: "El campo no debe tener más de 1500 caracteres"
                                    }, onChange: handleTextValuesChange
                                })}
                            />
                            {errors?.observacion && errors.observacion[respuesta.idCuestionarioRespuesta] &&
                                <Alert severity="error">
                                    <Typography>
                                        {errors.observacion[respuesta.idCuestionarioRespuesta].message}
                                    </Typography>
                                </Alert>}
                        </>) : (<Checkbox
                            checked={checkboxValues[respuesta.idCuestionarioRespuesta]}
                            key={childId}
                            // onChange={handleCheckboxChange}
                            name={respuesta.idCuestionarioRespuesta.toString()}
                        />)}
                        label={respuesta.cuestionarioRespuesta + (respuesta.observacion ? ' (' + respuesta.observacion + ')' : '')}
                        labelPlacement="top"
                    />

                </Fragment>)
            })}
            {errors?.respuesta?.pregunta[preguntaId] && <Alert severity="error">
                <Typography>
                    {errors?.respuesta?.pregunta[preguntaId].message}
                </Typography>
            </Alert>}
        </>);
    } else {
        return null;
    }
};

export default Respuestas;
