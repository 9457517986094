import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Undo as UndoIcon } from '@mui/icons-material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { reporteDetalle, concentradoDetalle } from "../actions/consultaAction";
import CustomDataGrid from "../components/encuestas/CustomDataGrid";
import {
    Grid, FormControl, InputLabel, MenuItem, Select, Button, Typography, Switch
} from "@mui/material";

import {
    useObtenerAnioQuery,
    useObtenerCarreraQuery,
    useObtenerEgresadoQuery,
    useObtenerDetalles1Query,
    useObtenerDetalles2Query,
    useObtenerEgresadoGeneracionQuery,
    useObtenerDetallesEncuestaQuery,
    useObtenerDetallesEncuesta2Query
} from "../services/consulta.service";
import { reloadEgresado } from '../slices/consultaSlice';
import Spinner from "../components/Spinner";
import { GridToolbarExport, GridToolbarContainer, GridToolbarDensitySelector, GridPagination } from '@mui/x-data-grid';


const AdminConsultas = () => {
    const {
        statusEncuestaDetalles, statusEncuestaCarrera, statusCarrera, statusDetalles, carreras, egresados, detalles
    } = useSelector((state) => state.consulta);

    const { data: anios, isSuccess } = useObtenerAnioQuery();

    const [inputGeneracion, setInputGeneracion] = useState('');
    const [inputCarrera, setInputCarrera] = useState('');
    const [generacion, setGeneracion] = useState('');
    const [carrera, setCarrera] = useState('');
    const [selectedCell, setSeletedCell] = useState(null);
    const [showGrid, setShowGrid] = useState(true);
    const [showGrid2, setShowGrid2] = useState(false);
    const [showGrid3, setShowGrid3] = useState(true);
    const [showGrid4, setShowGrid4] = useState(false);
    const [selectedGrid, setSelectedGrid] = useState(0);
    const [isSwitchVisible, setIsSwitchVisible] = useState(true);
    const [inputFecha1, setInputFecha1] = useState('');
    const [inputFecha2, setInputFecha2] = useState('');
    const [fecha1, setFecha1] = useState(null);
    const [fecha2, setFecha2] = useState(null);
    const [searchClicked, setSearchClicked] = useState(false);


    dayjs.locale('es');
    const columns = [
        {
            field: 'carrera',
            headerName: 'Carrera',
            flex: 1,
        },
        {
            field: 'alumnos',
            headerName: 'Total egresados',
            type: 'number',
            width: 150
        },
        {
            field: 'encuestados',
            headerName: 'Total encuestados',
            type: 'number',
            width: 150
        }, {
            field: 'encuestar',
            headerName: 'Por encuestar',
            type: 'number',
            width: 150
        },
    ];

    const columns2 = [
        {
            field: 'carrera',
            headerName: 'Carrera',
            flex: 1,
        },
        {
            field: 'generacion',
            headerName: 'Generación',
            align: 'right',
            width: 150
        },
        {
            field: 'alumnos',
            headerName: 'Total egresados',
            type: 'number',
            width: 150
        },
        {
            field: 'encuestados',
            headerName: 'Total encuestados',
            type: 'number',
            width: 150
        },
        {
            field: 'encuestar',
            headerName: 'Por encuestar',
            type: 'number',
            width: 150
        },
    ];

    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return `${day}/${month}/${year}`;
    };

    const cambioConsulta = () => {
        setGeneracion(inputGeneracion);
        setCarrera(inputCarrera);
    };

    const cambioConsultaFecha = () => {
        setFecha1(inputFecha1);
        setFecha2(inputFecha2);
    };

    const borrarConsulta = () => {
        setGeneracion(''); // Reinicia el estado de generación a su valor inicial
        setInputGeneracion('');
        setCarrera(''); // Reinicia el estado de carrera a su valor inicial
        setInputCarrera('');
        setSeletedCell(null); //Borra el grid de detalles
 
    };

    function handleCellClick(e) {
        switch (e.field) {
            case 'carrera':
                setSeletedCell({ idCarrera: e.row.idCarrera });
                setShowGrid(false);
                setSelectedGrid(0);
                setIsSwitchVisible(false);
                break;
            default:
        }

    }

    function handleCellClick2(e) {
        switch (e.field) {
            case 'generacion':
                setSeletedCell({ idCarrera: e.row.idCarrera, generacion: e.row.generacion });
                setShowGrid2(false);
                setSelectedGrid(1);
                setIsSwitchVisible(false);
                break;
            case 'carrera':
                setSeletedCell({ idCarrera: e.row.idCarrera });
                setShowGrid2(false);
                setSelectedGrid(1);
                setIsSwitchVisible(false);
                break;
            default:
        }

    }

    useObtenerAnioQuery();

    // Filtrar los egresados según las selecciones
    const filtradoEgresados = egresados?.filter((egresado) => {
        if (generacion && carrera) {
            return egresado.generacion == generacion && egresado.idCarrera == carrera;
        } else if (generacion) {
            return egresado.generacion == generacion;
        } else if (carrera) {
            return egresado.idCarrera == carrera;
        }
        return true;
    });

    const totalGeneral = filtradoEgresados?.reduce((total, egresado) => total + egresado.alumnos, 0);
    const totalEncuestados = filtradoEgresados?.reduce((total, egresado) => total + egresado.encuestados, 0);
    const totalPorEncuestar = filtradoEgresados?.reduce((total, egresado) => total + egresado.restantes, 0);


    const CarreraLoader = (props) => {
        useObtenerCarreraQuery();
        return;
    };

    const EgresadoLoader = (props) => {
        useObtenerEgresadoQuery()
        return;
    };

    const EgresadoGeneracionLoader = (props) => {
        useObtenerEgresadoGeneracionQuery()
        return;
    };

    const DetalleCarreraLoader = (props) => {
        useObtenerDetalles1Query(props.idCarrera)
        return;
    };

    const DetalleGeneracionLoader = (props) => {
        useObtenerDetalles2Query(props)
        return;
    };

    const DetalleEncuestaCarreraLoader = (props) => {
        useObtenerDetallesEncuesta2Query(props)
        return;
    };

    const DetalleEncuestaGeneracionLoader = (props) => {
        useObtenerDetallesEncuestaQuery(props)
        return;
    };

    const { userInfo } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [reporte, setReporte] = useState("");

    const handleReporteClick = () => {
        dispatch(reporteDetalle({ anio: inputGeneracion, token: userInfo.token, idCuestionario: 6 }))
            .then(({ payload }) => {
                setReporte(payload);
                // Decode the base64 string into a binary string
                var binaryString = window.atob(payload);
                // Create a Uint8Array from the binary string
                var uint8Array = new Uint8Array(binaryString.length);
                for (var i = 0; i < binaryString.length; i++) {
                    uint8Array[i] = binaryString.charCodeAt(i);
                }
                // Create a Blob object from the Uint8Array
                var blob = new Blob([uint8Array], { type: 'application/vnd.ms-excel' });
                // Create a URL for the Blob object
                var url = window.URL.createObjectURL(blob);
                // Create a link element and set its href attribute to the URL
                var link = document.createElement('a');
                link.href = url;
                // Set the download attribute to specify the filename
                link.setAttribute('download', `resultado_cuestionario.xlsx`);
                // Append the link element to the document body
                document.body.appendChild(link);
                // Click the link to trigger the download
                link.click();
                // Remove the link element from the document body
                document.body.removeChild(link);

            })
            .catch(error => {
                console.log("Error al obtener el reporte:", error);
            });
    };

    const handleConcentradoClick = () => {
        dispatch(concentradoDetalle({ anio: inputGeneracion, token: userInfo.token, idCuestionario: 6 }))
            .then(({ payload }) => {
                setReporte(payload);
                // Decode the base64 string into a binary string
                var binaryString = window.atob(payload);
                // Create a Uint8Array from the binary string
                var uint8Array = new Uint8Array(binaryString.length);
                for (var i = 0; i < binaryString.length; i++) {
                    uint8Array[i] = binaryString.charCodeAt(i);
                }
                // Create a Blob object from the Uint8Array
                var blob = new Blob([uint8Array], { type: 'application/vnd.ms-excel' });
                // Create a URL for the Blob object
                var url = window.URL.createObjectURL(blob);
                // Create a link element and set its href attribute to the URL
                var link = document.createElement('a');
                link.href = url;
                // Set the download attribute to specify the filename
                link.setAttribute('download', `resultado_concentrado.xlsx`);
                // Append the link element to the document body
                document.body.appendChild(link);
                // Click the link to trigger the download
                link.click();
                // Remove the link element from the document body
                document.body.removeChild(link);

            })
            .catch(error => {
                console.log("Error al obtener el reporte:", error);
            });
    };

    return (

        <Grid
            container
            spacing={0}
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            mt={8}
            mb={2}
            sx={{ textAlign: 'center', width: '80%' }}
        >

            <Grid container spacing={2} alignItems="center" direction="row">
                {isSwitchVisible && (
                    <Grid item ml={2} mt={2}>

                        <Switch checked={showGrid2} onChange={() => {
                            borrarConsulta();
                            setShowGrid(!showGrid);
                            setShowGrid2(!showGrid2);
                            dispatch(reloadEgresado())
                        }} />

                        <Typography style={{ display: 'inline-flex' }} variant="body2" fontWeight="bold">Filtrar por generación y carrera</Typography>
                    </Grid>
                )}
            </Grid>


            {isSuccess && <CarreraLoader />}
            {
                showGrid2 && (
                    <Grid container spacing={2} alignItems="center" mb={2}>
                        <Grid item ml={2}>
                            <FormControl sx={{ width: '300px', flexDirection: 'column', marginTop: '10px' }}>
                                <InputLabel id="generacion-label">Generación</InputLabel>
                                <Select
                                    labelId="generacion-label"
                                    id="generacion"
                                    label="Generación"
                                    value={inputGeneracion}
                                    onChange={(e) => { setInputGeneracion(e.target.value) }}
                                >
                                    <MenuItem value="">Todas</MenuItem>
                                    {isSuccess &&
                                        anios.map((gen) => (
                                            <MenuItem key={gen.anio} value={gen.anio}>
                                                {gen.anio}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item ml={2}>
                            <FormControl sx={{ width: '300px', flexDirection: 'column', marginTop: '10px' }}>
                                <InputLabel id="carrera-label">Carrera</InputLabel>
                                <Select
                                    labelId="carrera-label"
                                    id="carrera"
                                    label="Carrera"
                                    value={inputCarrera}
                                    onChange={(e) => { setInputCarrera(e.target.value) }}
                                >
                                    <MenuItem value="">Todas</MenuItem>
                                    {statusCarrera === 'succeeded' &&
                                        carreras.map((car) => (
                                            <MenuItem key={car.idCarrera} value={car.idCarrera}>
                                                {car.carrera}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item ml={2}>
                            <Button variant="contained" color="primary" onClick={cambioConsulta}>
                                Consultar
                            </Button>
                        </Grid>

                        <Grid item ml={2}>
                            <Button variant="contained" color="inherit" 
                            onClick={borrarConsulta}>
                                Limpiar
                            </Button>
                        </Grid>
                    </Grid>)
            }

            {
                showGrid && statusCarrera === 'succeeded' && (<Grid container pl={2} pr={2} style={{ cursor: 'pointer' }}>
                    <Box sx={{ height: 'calc(100vh - 180px)', width: '100%' }}>
                        <EgresadoGeneracionLoader />
                        <CustomDataGrid
                            rows={filtradoEgresados?.map((egresado) => ({
                                id: `${egresado.idCarrera}-${egresado.generacion}`,
                                idCarrera: egresado.idCarrera,
                                carrera: egresado.ofertaEducativa.carrera,
                                generacion: egresado.generacion,
                                alumnos: egresado.alumnos,
                                encuestados: egresado.encuestados,
                                encuestar: egresado.restantes
                            }))}
                            columns={columns}
                            slots={{
                                toolbar: () => (
                                    <GridToolbarContainer>
                                        <GridToolbarDensitySelector />
                                        <GridToolbarExport
                                            csvOptions={{ fileName: 'egresados', utf8WithBom: true }}
                                            printOptions={{ disableToolbarButton: filtradoEgresados.length > 100 }}
                                        />
                                        <Button variant="text" onClick={handleReporteClick}>
                                            <SummarizeIcon />&nbsp;Reporte
                                        </Button>
                                        <Button variant="text" onClick={handleConcentradoClick}>
                                            <SummarizeIcon />&nbsp;Concentrado
                                        </Button>
                                        <Box sx={{ mt: 5, ml: 11, }}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                CONCENTRADO DE EGRESADOS POR CARRERA
                                            </Typography>
                                        </Box>
                                    </GridToolbarContainer>
                                ),
                                pagination: (props) => (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <GridPagination {...props} sx={{ marginLeft: 'auto' }} />
                                        <Typography variant="subtitle2" sx={{ marginRight: '73px' }}>
                                            Total: {totalGeneral}
                                        </Typography>
                                        <Typography variant="subtitle2" sx={{ marginRight: '80px' }}>
                                            Total: {totalEncuestados}
                                        </Typography>
                                        <Typography variant="subtitle2" sx={{ marginRight: '30px' }}>
                                            Total: {totalPorEncuestar}
                                        </Typography>
                                    </Box>
                                ),
                            }}
                            onCellClick={handleCellClick}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            disableRowSelectionOnClick
                        />
                    </Box>
                </Grid>)
            }
            {
                showGrid2 && (<Grid container pl={2} pr={2} style={{ cursor: 'pointer' }}>
                    <Box sx={{ height: 'calc(100vh - 180px)', width: '100%' }}>
                        <EgresadoLoader />
                        <CustomDataGrid
                            rows={filtradoEgresados?.map((egresado) => ({
                                id: `${egresado.idCarrera}-${egresado.generacion}`,
                                idCarrera: egresado.idCarrera,
                                carrera: egresado.ofertaEducativa.carrera,
                                generacion: egresado.generacion,
                                alumnos: egresado.alumnos,
                                encuestados: egresado.encuestados,
                                encuestar: egresado.restantes
                            }))}
                            columns={columns2}
                            slots={{
                                toolbar: () => (
                                    <GridToolbarContainer>
                                        <GridToolbarDensitySelector />
                                        <GridToolbarExport
                                            csvOptions={{ fileName: 'egresados', utf8WithBom: true }}
                                            printOptions={{ disableToolbarButton: filtradoEgresados.length > 100 }}
                                        />
                                        <Button variant="text" onClick={handleReporteClick}>
                                            <SummarizeIcon />&nbsp;Reporte
                                        </Button>
                                        <Button variant="text" onClick={handleConcentradoClick}>
                                            <SummarizeIcon />&nbsp;Concentrado
                                        </Button>
                                        <Box sx={{ mt: 5, ml: 11, }}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                CONCENTRADO DE EGRESADOS POR GENERACION Y CARRERA
                                            </Typography>
                                        </Box>
                                    </GridToolbarContainer>
                                ),
                                pagination: (props) => (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <GridPagination {...props} sx={{ marginLeft: 'auto' }} />
                                        <Typography variant="subtitle2" sx={{ marginRight: '85px' }}>
                                            Total: {totalGeneral}
                                        </Typography>
                                        <Typography variant="subtitle2" sx={{ marginRight: '80px' }}>
                                            Total: {totalEncuestados}
                                        </Typography>
                                        <Typography variant="subtitle2" sx={{ marginRight: '30px' }}>
                                            Total: {totalPorEncuestar}
                                        </Typography>
                                    </Box>)
                            }}
                            onCellClick={handleCellClick2}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            disableRowSelectionOnClick
                        />
                    </Box>
                </Grid>)
            }


            {
                selectedCell && (<>
                    {statusDetalles === "loading" && (
                        <Grid container justifyContent="center" alignItems="center" direction="column" mt={9}>
                            <Spinner />
                        </Grid>)}
                    {selectedCell.generacion ?
                        !searchClicked && <DetalleGeneracionLoader idCarrera={selectedCell.idCarrera} anio={selectedCell.generacion} /> :
                        !searchClicked && <DetalleCarreraLoader idCarrera={selectedCell.idCarrera} />}


                    {showGrid3 && (<>

                        <Grid container pl={2} pr={2} mt={6}>
                            <Grid container spacing={2} alignItems="center" direction="row">
                                <Grid item ml={2} mt={2}>
                                    <Switch
                                        checked={showGrid4}
                                        onChange={() => {
                                            setShowGrid3(!showGrid3);
                                            setShowGrid4(!showGrid4);
                                            setFecha1('');
                                            setInputFecha1('');
                                            setFecha2('');
                                            setInputFecha2('');
                                            setSearchClicked(true);

                                            dispatch(reloadEgresado());


                                        }}
                                    />
                                    <Typography style={{ display: 'inline-flex' }} variant="body2" fontWeight="bold"> Filtrar encuestados</Typography>


                                </Grid>
                            </Grid>

                            <Box style={{ height: 'calc(100vh - 180px', width: '100%', overflow: 'auto' }}>
                                <CustomDataGrid
                                    rows={detalles?.map((detalle) => ({
                                        id: detalle.alumno.numeroControl,
                                        numeroControl: detalle.alumno.numeroControl,
                                        nombreCompleto: detalle.alumno.nombreCompleto,
                                        sexo: detalle.alumno.sexo,
                                        estatus: detalle.estatus.estatus,
                                        tipoIngreso: detalle.alumno.origen,
                                        telefono: detalle.alumno.telefono,
                                        celular: detalle.alumno.celular,
                                        municipio: detalle.municipio.municipio,
                                        entidadFederativa: detalle.entidadFederativa.entidadFederativa,
                                        carrera: detalle.ofertaEducativa.carrera,
                                        generacion: detalle.generacion,
                                        fechaEgreso: detalle.alumno.fechaEgreso,
                                        fechaEncuesta: detalle.fechaEncuesta,
                                        trabaja: detalle.trabaja,
                                        sectorEmpresa: detalle.sectorEmpresa,
                                        empresa: detalle.empresa,
                                    }))}
                                    columns={[
                                        { field: 'numeroControl', headerName: 'Matrícula', minWidth: 90 },
                                        { field: 'nombreCompleto', headerName: 'Nombre', minWidth: 350 },
                                        { field: 'sexo', headerName: 'Sexo' },
                                        { field: 'estatus', headerName: 'Estatus', minWidth: 120 },
                                        { field: 'tipoIngreso', headerName: 'Origen' },
                                        { field: 'telefono', headerName: 'Teléfono', minWidth: 200 },
                                        { field: 'celular', headerName: 'Celular', minWidth: 200 },
                                        { field: 'municipio', headerName: 'Municipio', minWidth: 200 },
                                        { field: 'entidadFederativa', headerName: 'E. Federativa', minWidth: 80 },
                                        { field: 'carrera', headerName: 'Carrera', minWidth: 330 },
                                        { field: 'generacion', headerName: 'Generación' },
                                        { field: 'fechaEgreso', headerName: 'F. Egreso' },
                                        { field: 'fechaEncuesta', headerName: 'F. Encuesta' },
                                        { field: 'trabaja', headerName: 'Trabaja' },
                                        { field: 'sectorEmpresa', headerName: 'Sector' ,minWidth:200 },
                                        { field: 'empresa', headerName: 'Empresa' ,minWidth:200 }
                                    ]}
                                    slots={{
                                        toolbar: () => (
                                            <GridToolbarContainer>
                                                <GridToolbarDensitySelector />
                                                <GridToolbarExport
                                                    csvOptions={{ fileName: 'egresados-detalles', utf8WithBom: true }}
                                                    printOptions={{ disableToolbarButton: detalles.length > 100 }}
                                                />
                                                <Button variant="text" onClick={() => { setShowGrid(selectedGrid === 0); setShowGrid2(selectedGrid === 1); setSeletedCell(null); setIsSwitchVisible(true); }}>
                                                    <UndoIcon />&nbsp;Regresar
                                                </Button>

                                                <Box sx={{ mt: 2, ml: 11, mb: 2 }}>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                        DETALLE DE EGRESADO POR CARRERA
                                                    </Typography>
                                                </Box>
                                            </GridToolbarContainer>),

                                    }}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 15,
                                            },
                                        },
                                    }}
                                    checkboxSelection={false}
                                    disableRowSelectionOnClick
                                />
                            </Box>

                        </Grid> </>)}


                </>)
            }

            {showGrid4 && (<>

                {statusEncuestaDetalles === "loading" && (

                    <Grid container justifyContent="center" alignItems="center" direction="column" mt={9}>
                        <Spinner />
                    </Grid>)}

                {selectedCell.generacion ? (
                    searchClicked && (
                        <DetalleEncuestaGeneracionLoader
                            idCarrera={selectedCell.idCarrera}
                            anio={selectedCell.generacion}
                            fechaInicio={inputFecha1}
                            fechaFinal={inputFecha2}
                        />
                    )
                ) : (
                    searchClicked && (
                        <DetalleEncuestaCarreraLoader
                            idCarrera={selectedCell.idCarrera}
                            fechaInicio={inputFecha1}
                            fechaFinal={inputFecha2}
                        />
                    )
                )}


                <Grid container pl={2} pr={2} mt={6}>
                    <Grid container spacing={2} alignItems="center" direction="row">
                        <Grid item ml={2} mt={2}>
                            <Switch
                                checked={showGrid4}
                                onChange={() => {
                                    setShowGrid3(!showGrid3);
                                    setShowGrid4(!showGrid4);
                                    setFecha1('');
                                    setInputFecha1('');
                                    setFecha2('');
                                    setInputFecha2('');
                                    setSearchClicked(false);
                                    dispatch(reloadEgresado());
                                }}
                            />

                            <Typography style={{ display: 'inline-flex' }} variant="body2" fontWeight="bold"> Filtrar encuestados</Typography>
                        </Grid>
                    </Grid>



                    <Grid container spacing={2} alignItems="center" mb={2}>

                        <Grid item ml={2}>
                            <Box sx={{ width: '300px', flexDirection: 'column', marginTop: '10px' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} locale="es">
                                    <DatePicker
                                        label="Fecha inicio"
                                        value={fecha1}
                                        onChange={(newFecha1) => setInputFecha1(newFecha1.format('DD/MM/YYYY'))}
                                        format='DD/MM/YYYY'
                                        disableFuture
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                        <Grid item ml={2}>
                            <Box sx={{ width: '300px', flexDirection: 'column', marginTop: '10px' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} locale="es">
                                    <DatePicker
                                        label="Fecha final"
                                        value={fecha2}
                                        onChange={(newFecha2) => setInputFecha2(newFecha2.format('DD/MM/YYYY'))}
                                        format='DD/MM/YYYY'
                                        disableFuture
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                        <Grid item ml={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setSearchClicked(true)
                                    dispatch(reloadEgresado());
                                }}
                            >
                                Consultar
                            </Button>



                        </Grid>
                        <Grid item ml={2}>
                            <Button variant="contained" color="inherit"
                                onClick={() => {
                                    setFecha1('');
                                    setInputFecha1('');
                                    setFecha2('');
                                    setInputFecha2('');
                                }}>
                                Limpiar
                            </Button>
                        </Grid>
                    </Grid>

                    <Box style={{ height: 'calc(100vh - 180px', width: '100%', overflow: 'auto' }}>
                        <CustomDataGrid
                            rows={detalles?.map((detalle) => ({
                                id: detalle.alumno.numeroControl,
                                numeroControl: detalle.alumno.numeroControl,
                                nombreCompleto: detalle.alumno.nombreCompleto,
                                sexo: detalle.alumno.sexo,
                                estatus: detalle.estatus.estatus,
                                tipoIngreso: detalle.alumno.origen,
                                telefono: detalle.alumno.telefono,
                                celular: detalle.alumno.celular,
                                municipio: detalle.municipio.municipio,
                                entidadFederativa: detalle.entidadFederativa.entidadFederativa,
                                carrera: detalle.ofertaEducativa.carrera,
                                generacion: detalle.generacion,
                                fechaEgreso: detalle.alumno.fechaEgreso,
                                fechaEncuesta: detalle.fechaEncuesta,
                                trabaja: detalle.trabaja,
                                sectorEmpresa: detalle.sectorEmpresa,
                                empresa: detalle.empresa,
                            }))}
                            columns={[
                                { field: 'numeroControl', headerName: 'Matrícula', minWidth: 90 },
                                { field: 'nombreCompleto', headerName: 'Nombre', minWidth: 350 },
                                { field: 'sexo', headerName: 'Sexo' },
                                { field: 'estatus', headerName: 'Estatus', minWidth: 120 },
                                { field: 'tipoIngreso', headerName: 'Origen' },
                                { field: 'telefono', headerName: 'Teléfono', minWidth: 200 },
                                { field: 'celular', headerName: 'Celular', minWidth: 200 },
                                { field: 'municipio', headerName: 'Municipio', minWidth: 200 },
                                { field: 'entidadFederativa', headerName: 'E. Federativa', minWidth: 80 },
                                { field: 'carrera', headerName: 'Carrera', minWidth: 330 },
                                { field: 'generacion', headerName: 'Generación' },
                                { field: 'fechaEgreso', headerName: 'F. Egreso' },
                                { field: 'fechaEncuesta', headerName: 'F. Encuesta' },
                                { field: 'trabaja', headerName: 'Trabaja' },
                                { field: 'sectorEmpresa', headerName: 'Sector' },
                                { field: 'empresa', headerName: 'Empresa' }
                            ]}
                            slots={{
                                toolbar: () => (
                                    <GridToolbarContainer>
                                        <GridToolbarDensitySelector />
                                        <GridToolbarExport
                                            csvOptions={{ fileName: 'egresados-detalles', utf8WithBom: true }}
                                            printOptions={{ disableToolbarButton: detalles.length > 100 }}
                                        />
                                        {/* <Button variant="text" onClick={() => { setShowGrid(selectedGrid === 0); setShowGrid2(selectedGrid === 1); setSeletedCell(null); setIsSwitchVisible(true); }}>
                            <UndoIcon />&nbsp;Regresar
                        </Button> */}
                                        <Box sx={{ mt: 2, ml: 11, mb: 2 }}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                DETALLE DE EGRESADO POR CARRERA
                                            </Typography>
                                        </Box>
                                    </GridToolbarContainer>),

                            }}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 15,
                                    },
                                },
                            }}
                            checkboxSelection={false}
                            disableRowSelectionOnClick
                        />
                    </Box>

                </Grid></>)}
        </Grid >
    );
};

export default AdminConsultas;