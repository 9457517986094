import React, {Component} from 'react';
import _ from 'lodash';

import {DataGrid} from '@mui/x-data-grid';
import Spinner from "../Spinner";
import {Grid} from '@mui/material';
import debounce from 'lodash/debounce';


export default class CustomDataGrid extends Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();

        const {columns} = props;
        this.state = {mappedColumns: _.clone(columns)};
        this.currentWidth = 0;
    }

    autoSizeColumns = () => {
        console.log('resize',  this.ref.current?.clientWidth)
        const domRows = [...this.ref.current?.querySelectorAll('.MuiDataGrid-row')];
        const domReady = (this.props.rows?.length === 0) || domRows.length;
        if (!domReady) {
            setTimeout(this.autoSizeColumns, 500);
            return;
        }
        
        if(this.currentWidth === this.ref.current?.clientWidth){
            return;
        }
        this.currentWidth = this.ref.current.clientWidth;

        this.setState(previousState => {
            const mappedColumns = _.clone(previousState.mappedColumns);
            let maxContentWidth = 0;
            mappedColumns
                .forEach((col, idx) => {
                    maxContentWidth += domRows
                        .reduce((previousMax, dR) => Math.max(previousMax, dR.childNodes[idx]?.scrollWidth), 0);

                });

            let availableContentWidth = Math.max(this.ref.current.clientWidth - maxContentWidth, 0);
            let itemsFullsize = mappedColumns.filter(e => !e.width).length
            let increaseWidth = 0;
            if (itemsFullsize > 0 && availableContentWidth > 0) {
                increaseWidth = availableContentWidth / itemsFullsize
            }

            if(increaseWidth > 0 && maxContentWidth > 0){
                mappedColumns
                    .forEach((col, idx) => {
                        if(!col.width && col.minWidth){
                            col.width = col.minWidth + increaseWidth;
                        }

                    });
            }
            return {mappedColumns, resized: true};
        });
    }

    render() {
        const {mappedColumns, resized} = this.state;
        const {columns, ...props} = this.props;

        return (
            <>
{/*                {!resized &&
                    <Grid container alignItems="center" justifyContent="center"
                          style={{opacity: 0.8, backgroundColor: 'white', textAlign: 'center', zIndex: 1}}>
                        <Spinner/>
                    </Grid>
                }*/}
                <DataGrid
                    ref={this.ref}
                    //onResize={debounce(this.autoSizeColumns, 1000)}
                    columns={mappedColumns}
                    pageSizeOptions={[5, 10, 15, 20, 50, 100]}
                    {...props /* eslint-disable-line react/jsx-props-no-spreading */}
                />
            </>
        );
    }
}